import { axiosInstance } from 'apis/_axios';

export const fetchCalendarApi = (year) => {
  return axiosInstance().post(`/admin/yearly-closing-period`, { year: year });
};

export const createHolidayApi = (data) => {
  return axiosInstance().post('/admin/closing-period', data);
};

export const deleteHolidayApi = (id) => {
  return axiosInstance().delete(`/admin/closing-period/${id}`);
};
