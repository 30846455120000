/* Build in libraries */
import { useEffect, useState } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import CustomTable from 'components/UI/Table';

/* Services */
import CustomModal from 'components/globals/modal';
import { fetchCompany } from 'redux/company/actions';
import CompanyDetail from '../CompanyDetail';
import CompanyUserForm from '../CompanyForm/CompanyUserDetail';
import EditUserDetails from '../CompanyForm/EditUserDetails';
import StatusChange from '../StatusChange/Index';

export default function CompanyTable() {
  const dispatch = useDispatch();
  const { companies, isLoading } = useSelector((state) => state.company);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [modalType, setModalType] = useState(true);
  const [detail, setDetail] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleModalOpen = (event, type, slug) => {
    event.stopPropagation();
    const companyDetail = companies?.data?.find((data) => data?.slug === slug);
    setDetail(companyDetail);
    setModalType(type);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(fetchCompany({ page: page, rowsPerPage: rowsPerPage }));
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (companies?.data?.length > 0) {
      const data = companies?.data?.map((data, index) => {
        return {
          name: data?.name,
          email: data?.company_detail[0]?.value,
          address: data?.address?.[0]?.city,
          tax_id: data?.tax_id,
          reg_no: data?.regon,
          slug: data?.slug,
          statusId: data?.status?.id,
          status: data?.status?.title
        };
      });
      setTableData(data);
    }
  }, [companies]);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 50 },
    { id: 'name', label: 'Company Name', minWidth: 170, cursor: 'pointer' },
    { id: 'email', label: 'Email', minWidth: 170 },
    {
      id: 'address',
      label: 'Address',
      minWidth: 170
    },
    {
      id: 'tax_id',
      label: 'Tax Id',
      minWidth: 170
    },
    {
      id: 'reg_no',
      label: 'Reg no.',
      minWidth: 170
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 170
    },
    {
      id: 'actions',
      label: 'Action',
      minWidth: 170
    }
  ];

  return (
    <>
      <CustomTable
        columns={tableHead}
        rows={tableData}
        handleModalOpen={handleModalOpen}
        handleClose={handleClose}
        loading={isLoading}
        setPage={setPage}
        page={page}
        total={companies?.meta?.total}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {modalType === 'detail' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="100rem"
          variant="light"
          headerText={`Detail of ${detail?.name}`}>
          <CompanyDetail company={detail} />
        </CustomModal>
      )}
      {modalType === 'userDetail' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={`Update ${detail?.name}'s User`}>
          <EditUserDetails handleClose={handleClose} slug={detail?.slug} />
        </CustomModal>
      )}
      {modalType === 'status' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={`Change Status of  ${detail?.name}`}>
          <StatusChange
            handleClose={handleClose}
            slug={detail?.slug}
            statusId={detail?.status?.id}
          />
        </CustomModal>
      )}
      {modalType === 'companyUser' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="80rem"
          variant="light"
          headerText={` ${detail?.name} Users`}>
          <CompanyUserForm handleClose={handleClose} slug={detail?.slug} />
        </CustomModal>
      )}
    </>
  );
}
