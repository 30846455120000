/* Build in libraries */
import React, { useEffect, useState } from 'react';

/* Third party libraries */
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import * as yup from 'yup';

/* Style */
import useStyles from '../../styles';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import { HookForm } from 'components/UI/Form/HookForm';
import { useDispatch, useSelector } from 'react-redux';
import { addCompanyAdmin } from 'redux/company/actions';
import EditForm from './EditForm';

const EditUserDetails = ({ slug }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { adminCreateLoading } = useSelector((state) => state.company);

  const defaultValues = {
    email: '',
    name: '',
    is_admin: false
  };
  const validationSchema = yup.object().shape({
    email: yup.string().email().required('User Email  is required'),
    name: yup.string().required('User name is required')
  });

  const submit = (data) => {
    console.log(data);
    dispatch(
      addCompanyAdmin(
        {
          company_slug: slug,
          user_name: data?.name,
          user_email: data?.email,
          is_admin: data?.is_admin
        },
        slug
      )
    );
  };

  return (
    <HookFormProvider
      defaultValues={defaultValues}
      resolver={useYupValidationResolver(validationSchema)}>
      <HookForm onSubmit={submit}>
        <Box px={4.7} py={3}>
          <EditForm />
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!adminCreateLoading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                Submit
              </Button>
            ) : (
              <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Submitting
              </Button>
            )}
          </Box>
        </Box>
      </HookForm>
    </HookFormProvider>
  );
};

export default EditUserDetails;
