import Delete from '@mui/icons-material/Delete';
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import EditUserDetails from './EditUserForm';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserStatus, getCompanyAdmin, removeUser } from 'redux/company/actions';
import CustomModal from 'components/globals/modal';
import useToggle from '../../../../hooks/useToggle';

function Index({ slug }) {
  const dispatch = useDispatch();
  const { companyAdmins, adminLoading } = useSelector((state) => state.company);
  useEffect(() => {
    slug && dispatch(getCompanyAdmin(slug));
  }, [slug]);

  return !adminLoading ? (
    <div>
      <EditUserDetails slug={slug} />
      <Box sx={{ margin: '30px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyAdmins?.map((list, index) => (
              <TableRow key={list?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{list?.name}</TableCell>
                <TableCell style={{ textTransform: 'none' }}>{list?.email}</TableCell>
                <TableCell>{list?.status == 1 ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{list?.is_admin == 1 ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Actions list={list} slug={slug} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  ) : (
    <Box textAlign="center" margin={10}>
      <CircularProgress />
    </Box>
  );
}

export default Index;

const Actions = ({ list, slug }) => {
  const classes = useStyles();
  const [openModal, openFunction] = useToggle(false);
  const [deleteModal, deleteFunction] = useToggle(false);
  const [userData, setUserData] = useState({ is_admin: list?.is_admin, status: list?.status });
  const dispatch = useDispatch();
  const { changeStatusLoading, removeUserLoading } = useSelector((state) => state.company);

  const handleChange = (e) => {
    setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleStatus = () => {
    dispatch(changeUserStatus({ company_slug: slug, user_id: list?.id, ...userData }));
  };

  const handleDelete = () => {
    dispatch(removeUser({ company_slug: slug, user_id: list?.id }));
  };
  console.log(list, 'list');
  return (
    <div className={classes.iconWrapper}>
      <Tooltip title="Change Status">
        <EditIcon onClick={openFunction} />
      </Tooltip>
      <Tooltip title="Remove User">
        <Delete onClick={deleteFunction} />
      </Tooltip>
      <CustomModal
        open={openModal}
        handleClose={openFunction}
        height="auto"
        width="40rem"
        variant="light"
        headerText={`Edit User`}>
        <Box margin={3}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h6" sx={{ display: 'inline-block' }}>
                Is Admin
              </Typography>
              <select
                onChange={handleChange}
                style={{ padding: '5px 10px', border: 'none', marginLeft: '15px' }}
                name="is_admin"
                value={userData?.is_admin}>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </Grid>
            <Grid item>
              <Typography variant="h6" sx={{ display: 'inline-block' }}>
                Status
              </Typography>
              <select
                value={userData?.status}
                onChange={handleChange}
                style={{ padding: '5px 10px', border: 'none', marginLeft: '15px' }}
                name="status">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!changeStatusLoading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleStatus}>
                Yes
              </Button>
            ) : (
              <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Yes
              </Button>
            )}

            <Button
              className={classes.modalFooterCancel}
              sx={{ background: '#fff', color: '#000' }}
              variant={'contained'}
              onClick={openFunction}>
              No
            </Button>
          </Box>
        </Box>
      </CustomModal>
      <CustomModal
        open={deleteModal}
        handleClose={deleteFunction}
        height="auto"
        width="40rem"
        variant="light"
        headerText={`Remove User`}>
        <Box textAlign="center" margin={5}>
          <Typography variant="h5">Do you want to remove user?</Typography>
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!removeUserLoading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleDelete}>
                Yes
              </Button>
            ) : (
              <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Yes
              </Button>
            )}

            <Button
              className={classes.modalFooterCancel}
              sx={{ background: '#fff', color: '#000' }}
              variant={'contained'}
              onClick={deleteFunction}>
              No
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </div>
  );
};
