/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Box, Typography } from '@mui/material';

/* Components */
import Breadcrumbs from 'components/UI/Breadcrumbs';
import Table from '../../../../components/Agreements/AgreementTable';

/* Style */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '& .saveButton': {
      background: theme.palette.primary.normal,
      textTransform: 'capitalize',
      borderRadius: '2px',
      color: '#fff',
      fontSize: '13px',
      height: '28px'
    },
    '& .saveButton:hover': {
      background: theme.palette.primary.normal
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const AgreementTable = () => {
  const classes = useStyles();

  return (
    <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className={classes.title}>
          <Typography sx={{ fontSize: '16px' }}>Agreement</Typography>
          <Breadcrumbs />
        </Box>
      </Box>
      <Box mt={1}>
        <Table />
      </Box>
    </Box>
  );
};

export default AgreementTable;
