export const FETCH_ACCOUNTING_BEGINS = 'FETCH_ACCOUNTING_BEGINS';
export const FETCH_ACCOUNTING_SUCCESS = 'FETCH_ACCOUNTING_SUCCESS';
export const FETCH_ACCOUNTING_ERROR = 'FETCH_ACCOUNTING_ERROR';

export const FETCH_SINGLE_ACCOUNTING_BEGINS = 'FETCH_SINGLE_ACCOUNTING_BEGINS';
export const FETCH_SINGLE_ACCOUNTING_SUCCESS = 'FETCH_SINGLE_ACCOUNTING_SUCCESS';
export const FETCH_SINGLE_ACCOUNTING_ERROR = 'FETCH_SINGLE_ACCOUNTING_ERROR';

export const CREATE_ACCOUNTING_BEGIN = 'CREATE_ACCOUNTING_BEGIN';
export const CREATE_ACCOUNTING_SUCCESS = 'CREATE_ACCOUNTING_SUCCESS';
export const CREATE_ACCOUNTING_ERROR = 'CREATE_ACCOUNTING_ERROR';

export const DELETE_ACCOUNTING_BEGIN = 'DELETE_ACCOUNTING_BEGIN';
export const DELETE_ACCOUNTING_SUCCESS = 'DELETE_ACCOUNTING_SUCCESS';
export const DELETE_ACCOUNTING_ERROR = 'DELETE_ACCOUNTING_ERROR';

export const CREATE_ACCOUNT_ADMIN_BEGIN = 'CREATE_ACCOUNT_ADMIN_BEGIN';
export const CREATE_ACCOUNT_ADMIN_SUCCESS = 'CREATE_ACCOUNT_ADMIN_SUCCESS';
export const CREATE_ACCOUNT_ADMIN_ERROR = 'CREATE_ACCOUNT_ADMIN_ERROR';

export const FETCH_WITHOUT_OFFICE_BEGINS = 'FETCH_WITHOUT_OFFICE_BEGINS';
export const FETCH_WITHOUT_OFFICE_SUCCESS = 'FETCH_WITHOUT_OFFICE_SUCCESS';
export const FETCH_WITHOUT_OFFICE_ERROR = 'FETCH_WITHOUT_OFFICE_ERROR';

export const FETCH_ASSIGNED_COMPANIES_BEGINS = 'FETCH_ASSIGNED_COMPANIES_BEGINS';
export const FETCH_ASSIGNED_COMPANIES_SUCCESS = 'FETCH_ASSIGNED_COMPANIES_SUCCESS';
export const FETCH_ASSIGNED_COMPANIES_ERROR = 'FETCH_ASSIGNED_COMPANIES_ERROR';

export const ASSIGN_OFFICE_BEGINS = 'ASSIGN_OFFICE_BEGINS';
export const ASSIGN_OFFICE_SUCCESS = 'ASSIGN_OFFICE_SUCCESS';
export const ASSIGN_OFFICE_ERROR = 'ASSIGN_OFFICE_ERROR';
