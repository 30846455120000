import { axiosInstance } from 'apis/_axios';

// signup data format = {name: string, email: string, password: string, password_confirmation: string}
export const signupApi = (data) => {
  return axiosInstance().post('/api/register', data);
};

// login data format {email: "", password: ""}
export const loginApi = (data) => {
  return axiosInstance().post('/admin/login', data);
};

/* forget-password */
export const forgetPasswordApi = (data) => {
  return axiosInstance().post('/api/password-reset', data);
};

/* reset-password */
export const resetPasswordApi = (data) => {
  return axiosInstance().post('/api/password-reset/change', data);
};
