import * as actions from './types';
const initialState = {
  isLoading: false,
  taxOffices: [],
  nfzBranches: [],
  diseaseCodes: [],
  breakBenefitTypes: [],
  expiration_loading: false,
  expirationCodes: [],
  deregistration_loading: false,
  deregistration: []
};

export const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MISCELLANEOUS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case actions.TAX_OFFICE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        taxOffices: action.payload
      };

    case actions.NFZ_BRANCH_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nfzBranches: action.payload
      };

    case actions.DISEASE_CODE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        diseaseCodes: action.payload
      };

    case actions.BREAK_BENEFIT_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        breakBenefitTypes: action.payload
      };

    case actions.TAX_OFFICE_FETCH_ERROR:
    case actions.NFZ_BRANCH_FETCH_ERROR:
    case actions.DISEASE_CODE_FETCH_ERROR:
    case actions.BREAK_BENEFIT_TYPE_FETCH_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case actions.EXPIRATION_CODE_FETCH_BEGIN:
      return {
        ...state,
        expiration_loading: true
      };

    case actions.EXPIRATION_CODE_FETCH_SUCCESS:
      return {
        ...state,
        expiration_loading: false,
        expirationCodes: action.payload
      };

    case actions.EXPIRATION_CODE_FETCH_ERROR:
      return {
        ...state,
        expiration_loading: false
      };
    case actions.DEREGISTRATION_FETCH_BEGIN:
      return {
        ...state,
        deregistration_loading: true
      };

    case actions.DEREGISTRATION_FETCH_SUCCESS:
      return {
        ...state,
        deregistration_loading: false,
        deregistration: action.payload
      };

    case actions.DEREGISTRATION_FETCH_ERROR:
      return {
        ...state,
        deregistration_loading: false
      };
    default:
      return { ...state };
  }
};
