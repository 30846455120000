import React from 'react';
import { Box } from '@mui/material';
import BreadCrumb from 'components/UI/breadCrumb';
import Taxoffice from 'components/Miscellaneous/Taxoffice';

function TaxOfficePage() {
  return (
    <>
      <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
        <BreadCrumb itemName={'Miscellaneous'} page={'Tax Office'} />
        <Box mt={1}>
          <Taxoffice />
        </Box>
      </Box>
    </>
  );
}

export default TaxOfficePage;
