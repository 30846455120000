import { axiosInstance } from 'apis/_axios';

export const addCompanyApi = (data) => {
  return axiosInstance().post('/admin/company', data);
};

export const fetchCompanyApi = (data) => {
  const page = data?.page + 1 ?? 1;
  const rowsPerPage = data?.rowsPerPage ?? 10;
  return axiosInstance().get(`/admin/company?page=${page}&limit=${rowsPerPage}`);
};

export const editCompanyApi = (slug, data) => {
  return axiosInstance().put(`/admin/company/${slug}`, data);
};

export const fetchSingleCompanyApi = (slug) => {
  return axiosInstance().get(`/admin/company/${slug}`);
};

export const updateCompanyAdminApi = (company_id, data) => {
  return axiosInstance().post(`/admin/company/${company_id}/admin`, data);
};

export const getCompanyAdminApi = (slug) => {
  return axiosInstance().get(`/admin/company-admin?company_slug=${slug}`);
};

export const addCompanyAdminApi = (data) => {
  return axiosInstance().post(`/admin/company-admin`, data);
};

export const changeUserStatusApi = (data) => {
  return axiosInstance().patch(`/admin/company-admin/change-status`, data);
};

export const removeUserApi = (data) => {
  return axiosInstance().patch(`/admin/company-admin/remove`, data);
};

export const changeCompanyStatusApi = (slug, data) => {
  return axiosInstance().patch(`/admin/company/${slug}/change-active`, data);
};
