import 'index.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setGlobalUser } from 'redux/auth/actions';
import Store from 'redux/store';
import RouteList from 'routes';

/**
 * this component is in charge of handeling
 * 1) Routes
 * 2) Toasts
 * */

if (localStorage.getItem('HRM_USER')) {
  Store.dispatch(setGlobalUser());
}

export default function App() {
  return (
    <div>
      <RouteList />
      <ToastContainer autoClose={4000} limit={3} draggablePercent={50} />
    </div>
  );
}
