/* Build in libraries */
import { useEffect } from 'react';

/* Third party libraries */
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

/* Components */
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { useFormContext } from 'react-hook-form';

function Registration({ backValidation, setBackValidation }) {
  const { setError, watch } = useFormContext();
  useEffect(() => {
    if (backValidation) {
      Object.entries(backValidation)?.map((item, index) =>
        setError(item[0], { message: item[1], type: 'custom' })
      );
    }
  }, [backValidation]);

  useEffect(() => {
    setBackValidation((prev) => ({ ...prev, company_detail_email: '' }));
  }, [watch('company_detail_email')]);

  useEffect(() => {
    setBackValidation((prev) => ({ ...prev, tax_id: '' }));
  }, [watch('tax_id')]);

  useEffect(() => {
    setBackValidation((prev) => ({ ...prev, user_email: '' }));
  }, [watch('user_email')]);
  return (
    <>
      <Box sx={{ borderBottom: '1px solid #D1D1DB' }}>
        <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
          <Typography className="title">Account Office Registration</Typography>
        </Box>
        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <HookFormInput
                required={true}
                name="name"
                label="Company Name"
                placeholder="Scodus Innovations"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                required={true}
                name="short_name"
                label="Short Name"
                placeholder="Scodus"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                required={true}
                name="email"
                label="Email"
                type="email"
                placeholder="sarao@gmail.com"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                required={true}
                name="tax_id"
                label="Tax Id"
                type="number"
                placeholder="848-095-1234"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="regon"
                required={true}
                label="Reg.no"
                placeholder="148-225-1354"
                tooltipLabel="Unique number-9 digits"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="bdo"
                label="BDO"
                placeholder="848-095-1234"
                tooltipLabel="Unique string nullable"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                type="number"
                name="phone_number"
                label="Phone number"
                required={true}
                placeholder="74747843"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="krs"
                required={true}
                label="KRS"
                placeholder="848-095-1234"
                tooltipLabel="Unique string nullable"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="pkd"
                label="PKD"
                placeholder="848-095-1234"
                tooltipLabel="Unique string nullable"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Registration;
