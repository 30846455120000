import Paper from '@mui/material/Paper';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ParameterView from './components/ParameterView';
import TaxBandView from './components/TaxBandView';
import { clearParameterByName } from './redux/actions';
import useStyles from './styles';

export default function ParameterTable() {
  const { parameters } = useSelector((state) => state.parameter);
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const socialRowsEmployee = [
    {
      id: 1,
      contribution: 'Pension Insurance (%)',
      key: 'pension_insurance_employee',
      value:
        parameters?.find((item) => item.par_name === 'pension_insurance_employee')?.par_value ||
        ' *'
    },
    {
      id: 2,
      contribution: 'Disability Insurance (%)',
      key: 'disability_insurance_employee',
      value:
        parameters?.find((item) => item.par_name === 'disability_insurance_employee')?.par_value ||
        ' *'
    },
    {
      id: 3,
      contribution: 'Sickness Insurance (%)',
      key: 'sickness_insurance_employee',
      value:
        parameters?.find((item) => item.par_name === 'sickness_insurance_employee')?.par_value ||
        ' *'
    }
  ];

  const socialRowsEmployer = [
    {
      id: 4,
      contribution: 'Pension Insurance',
      key: 'pension_insurance_employer',
      value:
        parameters?.find((item) => item.par_name === 'pension_insurance_employer')?.par_value ||
        ' *'
    },
    {
      id: 5,
      contribution: 'Disability Insurance (%)',
      key: 'disability_insurance_employer',
      value:
        parameters?.find((item) => item.par_name === 'disability_insurance_employer')?.par_value ||
        ' *'
    },
    {
      id: 6,
      contribution: 'Accident Insurance (%)',
      key: 'accident_insurance_employer',
      value:
        parameters?.find((item) => item.par_name === 'accident_insurance_employer')?.par_value ||
        ' *'
    },
    {
      id: 7,
      contribution: 'Insurance for labor fund (%)',
      key: 'labor_fund_insurance_employer',
      value:
        parameters?.find((item) => item.par_name === 'labor_fund_insurance_employer')?.par_value ||
        ' *'
    },
    {
      id: 8,
      contribution: 'Insurance for FGŚP (%)',
      key: 'fgsp_insurance_employer',
      value:
        parameters?.find((item) => item.par_name === 'fgsp_insurance_employer')?.par_value || ' *'
    },
    {
      id: 9,
      contribution: 'Insurance for FEP (%) - Bridge Pension Fund',
      key: 'fep_insurance_employer',
      value:
        parameters?.find((item) => item.par_name === 'fep_insurance_employer')?.par_value || ' *'
    }
  ];

  const healthContributionRows = [
    {
      id: 10,
      contribution: '30-times Social Security (PLN)',
      key: 'thirty_times_social_security',
      value:
        parameters?.find((item) => item.par_name === 'thirty_times_social_security')?.par_value ||
        ' *'
    },
    {
      id: 10,
      contribution: 'NFZ Contribution (%)',
      key: 'health_insurance_employee',
      value:
        parameters?.find((item) => item.par_name === 'health_insurance_employee')?.par_value || ' *'
    }
  ];
  const taxElements = [
    {
      id: 11,
      contribution: 'Lack (PLN)',
      key: 'tax_cost_lack',
      value: parameters?.find((item) => item.par_name === 'tax_cost_lack')
        ? parameters?.find((item) => item.par_name === 'tax_cost_lack').par_value
        : ' *'
    },
    {
      id: 12,
      contribution: 'Usual (PLN)',
      key: 'tax_cost_usual',
      value: parameters?.find((item) => item.par_name === 'tax_cost_usual')?.par_value || ' *'
    },
    {
      id: 13,
      contribution: 'Elevated (PLN)',
      key: 'tax_cost_elevated',
      value: parameters?.find((item) => item.par_name === 'tax_cost_elevated')?.par_value || ' *'
    }
  ];
  const taxDiscount = [
    {
      id: 14,
      contribution: 'Tax Allowance (PLN)',
      key: 'tax_allowance',
      value: parameters?.find((item) => item.par_name === 'tax_allowance')?.par_value || ' *'
    },
    {
      id: 15,
      contribution: 'Tax Credit Age',
      key: 'tax_credit_age',
      value: parameters?.find((item) => item.par_name === 'tax_credit_age')?.par_value || ' *'
    },
    {
      id: 16,
      contribution: 'Tax Relief Amount : PLN (Eg: for 26 years age)',
      key: 'tax_relief_free_amount',
      value:
        parameters?.find((item) => item.par_name === 'tax_relief_free_amount')?.par_value || ' *'
    },
    {
      id: 17,
      contribution: 'Tax Bands',
      key: 'tax_band_rate',
      value: '-'
    },
    {
      id: 18,
      contribution: 'Minimum wage for Work',
      key: 'employment_minimum_wage',
      value:
        parameters?.find((item) => item.par_name === 'employment_minimum_wage')?.par_value || ' *'
    },
    {
      id: 19,
      contribution: 'Tax Rate for Non-Residents (%)',
      key: 'tax_rate_for_non_residents',
      value:
        parameters?.find((item) => item.par_name === 'tax_rate_for_non_residents')?.par_value ||
        ' *'
    }
  ];
  const holidayReferences = [
    {
      id: 19,
      contribution: 'Vacation Duration (<10 years)',
      key: 'vacation_duration',
      value: parameters?.find((item) => item.par_name === 'vacation_duration')?.par_value || ' *'
    },
    {
      id: 20,
      contribution: 'Vacation Duration (>=10 years)',
      key: 'vacation_duration_ten_years',
      value:
        parameters?.find((item) => item.par_name === 'vacation_duration_ten_years')?.par_value ||
        ' *'
    },
    {
      id: 21,
      contribution: 'Limit of sick pay days',
      key: 'sick_days_limit',
      value: parameters?.find((item) => item.par_name === 'sick_days_limit')?.par_value || ' *'
    },
    {
      id: 22,
      contribution: 'Limit of sick pay days for people over 50 years age',
      key: 'sick_days_limit_over_fifty',
      value:
        parameters?.find((item) => item.par_name === 'sick_days_limit_over_fifty')?.par_value ||
        ' *'
    },
    {
      id: 23,
      contribution: 'Limit of sickness benefit',
      key: 'sickness_benefit_limit',
      value:
        parameters?.find((item) => item.par_name === 'sickness_benefit_limit')?.par_value || ' *'
    },
    {
      id: 24,
      contribution: 'Limit of sickness benefit (tuberculosis/pregnancy)',
      key: 'sickness_benefit_limit_exception',
      value:
        parameters?.find((item) => item.par_name === 'sickness_benefit_limit_exception')
          ?.par_value || ' *'
    },
    {
      id: 24,
      contribution: 'Equivalent Factor',
      key: 'vacation_equivalent_factor',
      value:
        parameters?.find((item) => item.par_name === 'vacation_equivalent_factor')?.par_value ||
        ' *'
    }
  ];
  const mandateSocialContributionRows = [
    {
      id: 25,
      contribution: 'Limit of sickness contribution',
      key: 'sickness_contribution_limit_mandate',
      value:
        parameters?.find((item) => item.par_name === 'sickness_contribution_limit_mandate')
          ?.par_value || ' *'
    }
  ];
  const mandateIncomeCostsRows = [
    {
      id: 26,
      contribution: 'Cost of getting Income (Lack) %',
      key: 'mandate_tax_cost_lack',
      value: parameters?.find((item) => item.par_name === 'mandate_tax_cost_lack')
        ? parameters?.find((item) => item.par_name === 'mandate_tax_cost_lack')?.par_value
        : ' *'
    },
    {
      id: 27,
      contribution: 'Cost of getting Income (Standard) %',
      key: 'mandate_tax_cost_standard',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_cost_standard')?.par_value || ' *'
    },
    {
      id: 28,
      contribution: 'Cost of getting Income (Copyright) %',
      key: 'mandate_tax_cost_copyright',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_cost_copyright')?.par_value ||
        ' *'
    }
  ];
  const mandateTaxAllowanceRows = [
    {
      id: 29,
      contribution: 'Tax allowance (Lack) PLN',
      key: 'mandate_tax_allownace_lack',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_lack')?.par_value ||
        ' *'
    },
    {
      id: 30,
      contribution: 'Tax Allowance (1/3) PLN',
      key: 'mandate_tax_allownace_one_third',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_one_third')
          ?.par_value || ' *'
    },
    {
      id: 31,
      contribution: 'Tax Allowance (1/2) PLN',
      key: 'mandate_tax_allownace_half',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_half')?.par_value ||
        ' *'
    },
    {
      id: 32,
      contribution: 'Tax Allowance (Single) PLN',
      key: 'mandate_tax_allownace_single',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_single')?.par_value ||
        ' *'
    }
  ];
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab
              label={<Typography variant="h5">Employement Contract</Typography>}
              {...a11yProps(0)}
            />
            <Tab
              label={<Typography variant="h5">Contract of Mandate</Typography>}
              {...a11yProps(1)}
            />
            {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h5">Company Paramters (Employment Contract)</Typography>
            </Grid>
            <Grid item sm={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.N</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Social Contribution </strong>(By Employee)
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {socialRowsEmployee.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Social Contribution </strong>(By Employer)
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {socialRowsEmployer.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>NFZ Contribution</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {healthContributionRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Cost of Getting Income</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {taxElements.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Tax</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {taxDiscount.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            {item.key !== 'tax_band_rate' ? (
                              <Actions parameter={item} />
                            ) : (
                              <TaxBandAction parameter={item} />
                            )}
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Vacation/Sickness</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {holidayReferences.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            {item.key !== 'tax_band_rate' ? (
                              <Actions parameter={item} />
                            ) : (
                              <TaxBandAction parameter={item} />
                            )}
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Box display="flex" justifyContent="right">
                <Typography variant="h5">*Values not set</Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h5">Company Paramters (Contract of Mandate)</Typography>
            </Grid>
            <Grid item sm={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.N</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Social Contribution </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {mandateSocialContributionRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Cost Of Getting Income</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {mandateIncomeCostsRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>Tax Allowance</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {mandateTaxAllowanceRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Box display="flex" justifyContent="right">
                <Typography variant="h5">*Values not set</Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>

      {/* <Grid container spacing={3} sx={{ marginTop: '10px' }}>
        <Grid item sm={12}>
          <Typography variant="h5">Taxation</Typography>
        </Grid>
        <Grid item sm={12}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <CustomTable tableData={taxationRows} tableHeads={columns} />
          </Paper>
        </Grid>
      </Grid> */}
    </>
  );
}

const Actions = ({ parameter }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(clearParameterByName());
    openFunction();
  };
  const classes = useStyles();

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title="View Parameter">
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="45rem"
        headerText={parameter?.contribution}>
        <ParameterView handleClose={openFunction} parameter={parameter} />
      </CustomModal>
    </>
  );
};

const TaxBandAction = ({ parameter }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    openFunction();
    dispatch(clearParameterByName());
  };
  const classes = useStyles();

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title="View Tax Band">
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="45rem"
        headerText={parameter?.contribution}>
        <TaxBandView handleClose={openFunction} parameter={parameter} />
      </CustomModal>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
