import moment from 'moment';

export const changeDate = (date, format = 'MM DD YYYY') => {
  return moment(date).format(format);
};

export const dateDetail = (date) => {
  const startOfMonth = moment(date).clone().startOf('month');
  const endOfMonth = moment(date).clone().endOf('month');
  const difference = endOfMonth.diff(startOfMonth, 'days');
  const weekDay = moment(startOfMonth).isoWeekday();
  const month = monthNames[moment(date).clone().month()] || '';
  return {
    startOfMonth: startOfMonth,
    endOfMonth: endOfMonth,
    difference: difference,
    weekDay: weekDay,
    monthName: month
  };
};

export const checkEqualDate = (startDate, endDate) => {
  return moment(changeDate(startDate)).isSame(changeDate(endDate));
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
