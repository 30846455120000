import React from 'react';
import useToggle from 'hooks/useToggle';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import BreadCrumb from 'components/UI/breadCrumb';
import Calendar from 'components/calendar/Calendar';

function CalendarPage() {
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
        <BreadCrumb
          itemName={'Calendar'}
          page={'Calendar'}
          handleButton={openFunction}
          buttonTitle="Add Closing Period"
        />
        <Calendar openFunction={openFunction} modalOpen={modalOpen} />
      </Box>
    </>
  );
}

export default CalendarPage;
