import { makeStyles } from '@mui/styles';

const usestyles = makeStyles((theme) => ({
  root: {
    background: '#ddd',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24',
    p: '4'
  },
  modalHeader: {
    background: theme.palette.modalHead,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2rem 2.5rem 2rem 3.8rem',
    height: '4.2rem',
    color: '#fff',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.18)'
  },
  modalHeaderText: {
    color: '#fff',
    fontFamily: 'Euclid',
    fontSize: '1.4rem',
    fontWeight: '600'
  },
  cancelItem: {
    cursor: 'pointer'
  },
  modalBody: {
    background: '#f9f9fb'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem 2rem 3rem',
    height: '6rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  }
}));

export default usestyles;
