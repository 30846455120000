/* Build in libraries */

/* Third party libraries */
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

/* Components */
import { HookFormInput } from 'components/UI/Form/HookFormInput';

function UserDetails({ showTitle = true }) {
  return (
    <>
      <Box>
        {showTitle && (
          <Box>
            <Typography className="title">User Details</Typography>
          </Box>
        )}

        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <HookFormInput name="user_name" label="Full Name" placeholder="Anmol Singh" />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="user_email"
                type="email"
                label="Email Address"
                placeholder="singh@gmail.com"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default UserDetails;
