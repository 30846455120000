import { Grid } from '@mui/material';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { useFormContext } from 'react-hook-form';

const BusinessAddressForm = ({ index, type }) => {
  const { register } = useFormContext();

  return (
    <>
      <Grid container spacing={3}>
        <input type="hidden" {...register(`addresses[${index}][type]`)} defaultValue={type} />

        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].country`}
            required={true}
            label="Country"
            placeholder="Nepal"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].street`}
            label="Street"
            placeholder="848 St road"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].house_number`}
            label="House Number"
            placeholder="848 St road"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].number_of_premises`}
            label="No. of premises"
            placeholder="848 St road"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].zip`}
            label="Zip code"
            placeholder="001354"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].post`}
            label="Post"
            placeholder="595959"
          />
        </Grid>

        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].community`}
            label="Town"
            placeholder="Bagbazar"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].county`}
            label="District"
            placeholder="abc"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].voivodeship`}
            label="Volvodeship"
            placeholder="xyz"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].city`}
            label="Commune"
            placeholder="Kathmandu"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default BusinessAddressForm;
