/* eslint-disable */
import newParameterReducer from 'components/NewParameters/redux/reducers';
import { combineReducers } from 'redux';
import { accountingReducer } from './accounting/reducers';
import { agreementTypeReducer } from './agreementType/reducers';
import authReducer from './auth/reducers';
import { calendarReducer } from './calendar/reducers';
import { companyReducer } from './company/reducers';
import { miscellaneousReducer } from './miscellaneous/reducers';
// import { parameterReducer } from './parameter/reducer';

export default combineReducers({
  authUser: authReducer,
  company: companyReducer,
  // globalParameters: parameterReducer, // Old Reducer for parameters
  parameter: newParameterReducer,
  agreementType: agreementTypeReducer,
  calendar: calendarReducer,
  miscellaneous: miscellaneousReducer,
  accounting: accountingReducer
});
