/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Box, Typography } from '@mui/material';

/* Components */
import PasswordChange from '../../../../components/Settings/Password';
import Breadcrumbs from 'components/UI/Breadcrumbs';

/* Style */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '& .saveButton': {
      background: theme.palette.primary.normal,
      textTransform: 'capitalize',
      borderRadius: '2px',
      color: '#fff',
      fontSize: '13px',
      height: '28px'
    },
    '& .saveButton:hover': {
      background: theme.palette.primary.normal
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Password = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        pt={9}
        pl={32}
        pr={3}
        pb={0.5}
        sx={{ background: '#fff', borderBottom: '1px solid rgb(140 132 132 / 25%) !important' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.title}>
            <Typography sx={{ fontSize: '16px' }}>Password</Typography>
            <Breadcrumbs />
          </Box>
        </Box>
      </Box>
      <Box pt={2} pl={32} pr={3} pb={0.5} sx={{ background: '#f5f5f5', height: '100vh' }}>
        <PasswordChange />
      </Box>
    </>
  );
};

export default Password;
