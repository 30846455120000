/* Build in libraries */
import React, { useEffect, useState } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import CustomTable from 'components/UI/Table';

/* Services */
import { fetchCompany } from 'redux/company/actions';
import LoadingTable from 'components/UI/Loading/LoadingTable';
import CustomModal from 'components/globals/modal';
import {
  Button,
  CircularProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import Footer from 'components/globals/modal/footer';
import { changeAgreementType, fetchAgreementType } from 'redux/agreementType/actions';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../styles';
import useToggle from 'hooks/useToggle';

export default function CompanyTable() {
  const dispatch = useDispatch();
  const { agreementTypes, isLoading } = useSelector((state) => state.agreementType);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
    {
      id: 'actions',
      label: 'Action',
      minWidth: 170
    }
  ];

  useEffect(() => {
    dispatch(fetchAgreementType());
  }, []);

  // useEffect(() => {
  //   agreementTypes &&
  //     agreementTypes.length > 0 &&
  //     setData(
  //       agreementTypes.map((item) => ({
  //         name: item?.name,
  //         slug: item?.slug,
  //         status: item?.status?.title
  //       }))
  //     );
  // }, [agreementTypes]);

  return (
    <>
      {/* <CustomTable
        columns={tableHead}
        rows={data}
        actionType="agreementAction"
        handleModalOpen={handleModalOpen}
        handleClose={handleClose}
      /> */}
      <Table>
        <TableHead>
          <TableRow>
            {tableHead?.map((item) => (
              <TableCell key={item?.id}>{item?.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading
            ? agreementTypes?.map((item, index) => (
                <RowComponent item={item} key={item?.slug} sn={index + 1} />
              ))
            : [...Array(5).keys()].map((index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton height={30} />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </>
  );
}

const RowComponent = ({ item, sn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, handleClose] = useToggle(false);
  const { agreement_status_loading } = useSelector((state) => state.agreementType);

  const handleSubmit = () => {
    const status = item.status?.id === 1 ? 0 : 1;
    dispatch(changeAgreementType(item?.slug, { status_id: status, _method: 'patch' }, handleClose));
  };

  return (
    <>
      <TableRow>
        <TableCell>{sn}</TableCell>
        <TableCell>{item?.name}</TableCell>
        <TableCell>{item?.status?.title}</TableCell>
        <TableCell>
          <Tooltip title="Change Status">
            <EditIcon className={classes.toggle} onClick={handleClose} />
          </Tooltip>
        </TableCell>
      </TableRow>
      <CustomModal
        open={openModal}
        handleClose={handleClose}
        height="auto"
        width="50rem"
        variant="light"
        headerText="Change Status">
        <Box p={4} py={6}>
          <Typography variant="h5" textAlign="center">
            Do you want to change the status?
          </Typography>
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!agreement_status_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
                {item?.status?.id === 1 ? 'Deactivate' : 'Activate'}
              </Button>
            ) : (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                <CircularProgress size={16} sx={{ marginRight: '10px' }} /> Activate
              </Button>
            )}
            <Button
              className={classes.modalFooterCancel}
              sx={{ background: '#fff', color: '#000' }}
              variant={'contained'}
              onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};
