/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Box } from '@mui/material';

/* Components */
import AddCompanyForm from 'components/Company/CompanyForm';
import Breadcrumbs from 'components/UI/Breadcrumbs';

function AddCompany() {
  return (
    <>
      <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
        <Breadcrumbs />
        <AddCompanyForm />
      </Box>
    </>
  );
}

export default AddCompany;
