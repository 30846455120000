import { Save } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/UI/Form/HookForm';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import * as yup from 'yup';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { storeHoliday } from 'redux/calendar/actions';
import { changeDate } from 'utils/dateUtils';

function AddCalendar({ openFunction, currentDate }) {
  const { isLoading } = useSelector((state) => state.calendar);
  const classes = useStyles();
  const validationSchema = yup.object({
    closing_date: yup.date().required(),
    title: yup.string().required()
  });
  const dispatch = useDispatch();

  const defaultValues = {
    closing_date: '',
    title: ''
  };

  const onSubmit = (data) => {
    dispatch(
      storeHoliday(
        { ...data, closing_date: changeDate(data?.closing_date, 'YYYY-MM-DD') },
        openFunction,
        currentDate?.getFullYear()
      )
    );
  };

  return (
    <div className={classes.cardWrapper}>
      <HookFormProvider
        defaultValues={defaultValues}
        resolver={useYupValidationResolver(validationSchema)}>
        <HookForm onSubmit={onSubmit}>
          <FormContent />
          <Box className="buttonWrapper">
            <Button className="saveButton" type="submit" disabled={isLoading}>
              {isLoading ? 'Saving' : 'Save'}
            </Button>

            <Button className="cancelButton" onClick={openFunction}>
              Cancel
            </Button>
          </Box>
        </HookForm>
      </HookFormProvider>
    </div>
  );
}

export default AddCalendar;

const FormContent = () => {
  const {
    formState: { errors }
  } = useFormContext();
  return (
    <Box marginX={4} marginY={2}>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <HookFormInput name="closing_date" type="date" label="Closing Date" />
        </Grid>
        <Grid item sm={12}>
          <HookFormInput name="title" label="Title" placeholder="Holiday Title" />
        </Grid>
      </Grid>
    </Box>
  );
};
