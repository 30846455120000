import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import InputField from 'components/globals/inputField';
import PasswordField from 'components/globals/passwordInput';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import AuthLayout from 'layouts/authLayout';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Login as LoginAction } from 'redux/auth/actions';
import { isLoggedIn } from 'utils';
import * as yup from 'yup';
import useStyles from './styles';

const Login = () => {
  const classes = useStyles();
  const [signInLoading, setSignInLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required('Password is required.')
  });
  const resolver = useYupValidationResolver(schema);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver
  });

  const appVersion = () => {
    return window?._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION || "?.?";
  };
  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/app/dashboard');
    }
  }, []);

  const goToDashboard = () => {
    return navigate('/app/dashboard');
  };
  // submit function
  const handleBtnClick = (data) => {
    setSignInLoading(true);
    dispatch(LoginAction(data, setSignInLoading, goToDashboard));
  };
  return (
    <AuthLayout heading="Admin Log In">
      <form onSubmit={handleSubmit(handleBtnClick)}>
        <InputField
          name="email"
          register={register}
          label="Email"
          type="text"
          placeholder="Email Here"
          error={errors.email}
          isRequired
        />
        <br />
        <PasswordField
          name={'password'}
          label="Password"
          placeholder="Password here"
          register={register}
          isRequired
          error={errors.password}
        />

        <Box mt={3}>
          {!signInLoading && (
            <Button
              className={classes.submitButton}
              sx={{ fontSize: '16px', fontWeight: '700' }}
              variant="contained"
              type={'submit'}>
              Sign In
            </Button>
          )}
          {signInLoading && (
            <Button
              className={classes.submitButton}
              sx={{ fontSize: '16px', fontWeight: '700' }}
              variant="contained"
              type={'submit'}>
              <Typography className={classes.circularProgressText}>Signing in</Typography>
              <CircularProgress size={20} sx={{ color: '#fff', marginLeft: '1.5rem' }} />
            </Button>
          )}
        </Box>
        <br />
        <Box display="flex" alignItems="center" columnGap={0.5}>
          <Typography className={classes.endText} align="center">
            Having problem logging in ?
          </Typography>
          <Link to="/forget-password" style={{ textDecoration: 'none' }}>
            <Typography sx={{ fontSize: '13px', color: '#496AD0', cursor: 'pointer' }}>
              Forget Password
            </Typography>
          </Link>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" columnGap={0.5}>
          <Typography className={classes.endText} align="center">
            v{appVersion()}
          </Typography>
        </Box>
      </form>
    </AuthLayout>
  );
};
export default Login;
