import { Box, Button, Grid } from '@mui/material';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../styles';

function ParameterForm({ handleClose }) {
  const classes = useStyles();
  const { create_loading } = useSelector((state) => state.parameter);

  return (
    <>
      <div className={classes.formContent}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <HookFormInput
              name="date_s"
              label="Start Date"
              placeholder="Date applicable from"
              type="date"
            />
          </Grid>
          <Grid item sm={12}>
            <HookFormInput name="par_value" type="number" label="Value" placeholder="Value" />
          </Grid>
        </Grid>
      </div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          {!create_loading ? (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
              Save
            </Button>
          ) : (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
              Submitting
            </Button>
          )}

          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ParameterForm;
