export const MISCELLANEOUS_LOADING = 'MISCELLANEOUS_LOADING';

export const TAX_OFFICE_FETCH_SUCCESS = 'TAX_OFFICE_FETCH_SUCCESS';
export const TAX_OFFICE_FETCH_ERROR = 'TAX_OFFICE_FETCH_ERROR';

export const NFZ_BRANCH_FETCH_SUCCESS = 'NFZ_BRANCH_FETCH_SUCCESS';
export const NFZ_BRANCH_FETCH_ERROR = 'NFZ_BRANCH_FETCH_ERROR';

export const DISEASE_CODE_FETCH_SUCCESS = 'DISEASE_CODE_FETCH_SUCCESS';
export const DISEASE_CODE_FETCH_ERROR = 'DISEASE_CODE_FETCH_ERROR';

export const BREAK_BENEFIT_TYPE_FETCH_SUCCESS = 'BREAK_BENEFIT_TYPE_FETCH_SUCCESS';
export const BREAK_BENEFIT_TYPE_FETCH_ERROR = 'BREAK_BENEFIT_TYPE_FETCH_ERROR';

export const EXPIRATION_CODE_FETCH_BEGIN = 'EXPIRATION_CODE_FETCH_BEGIN';
export const EXPIRATION_CODE_FETCH_SUCCESS = 'EXPIRATION_CODE_FETCH_SUCCESS';
export const EXPIRATION_CODE_FETCH_ERROR = 'EXPIRATION_CODE_FETCH_ERROR';

export const DEREGISTRATION_FETCH_BEGIN = 'DEREGISTRATION_FETCH_BEGIN';
export const DEREGISTRATION_FETCH_SUCCESS = 'DEREGISTRATION_FETCH_SUCCESS';
export const DEREGISTRATION_FETCH_ERROR = 'DEREGISTRATION_FETCH_ERROR';
