import { SET_AUTH_USER } from './types';
import { forgetPasswordApi, loginApi, resetPasswordApi, signupApi } from 'apis/auth';
import { errorToast, successToast } from 'utils/toast';
const setGlobalUser = () => (dispatch) => {
  try {
    const user = localStorage.getItem('HRM_USER');
    const userObj = JSON.parse(user);
    dispatch({
      type: SET_AUTH_USER,
      payload: userObj
    });
  } catch (error) {
    errorToast(error);
  }
};
// login api
const Login =
  (data, setSignInLoading = null, goToDashboard = null) =>
  async (dispatch) => {
    try {
      const loginData = await loginApi(data);
      const token = loginData?.data?.data?.token;
      const refreshToken = loginData?.data?.data?.refresh_token;
      const expiresIn = loginData?.data?.data?.expires_in;
      const user = loginData?.data?.data?.user;
      // 2. setting token to the localStorage
      localStorage.setItem('HRM_TOKEN', token);
      localStorage.setItem('HRM_REFRESH_TOKEN', refreshToken);
      localStorage.setItem('HRM_TOKEN_EXPIRY', expiresIn);
      localStorage.setItem('HRM_USER', JSON.stringify(user));
      setSignInLoading && setSignInLoading(false);
      dispatch(setGlobalUser());
      goToDashboard && goToDashboard();
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setSignInLoading && setSignInLoading(false);
    }
  };
const logout = () => {
  localStorage.removeItem('HRM_TOKEN');
  localStorage.removeItem('HRM_REFRESH_TOKEN');
  localStorage.removeItem('HRM_TOKEN_EXPIRY');
  localStorage.removeItem('HRM_USER');
};

/* forget-password */
const forgetPassword =
  (data, setSignInLoading = null) =>
  async (dispatch) => {
    try {
      const response = await forgetPasswordApi(data);
      setSignInLoading && setSignInLoading(false);
      successToast('Email sent successfully');
    } catch (error) {
      console.log(error);
      errorToast(error?.response?.data.errors?.email);
      setSignInLoading && setSignInLoading(false);
    }
  };

/* reset-password */
const resetPassword =
  (data, setSignInLoading = null, goToDashboard = null) =>
  async (dispatch) => {
    try {
      const response = await resetPasswordApi(data);
      setSignInLoading && setSignInLoading(false);
      successToast('Password successfully reset. ');
      goToDashboard && goToDashboard();
    } catch (error) {
      console.log(error);
      errorToast(error?.response?.data.errors?.email);
      setSignInLoading && setSignInLoading(false);
    }
  };

/* Register */
const signup =
  (data, setSignInLoading = null, goToDashboard = null) =>
  async (dispatch) => {
    try {
      const response = await signupApi(data);
      setSignInLoading && setSignInLoading(false);
      successToast('Successfully Signed Up');
      goToDashboard && goToDashboard();
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setSignInLoading && setSignInLoading(false);
    }
  };
export { Login, logout, setGlobalUser, forgetPassword, signup, resetPassword };
