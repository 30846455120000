import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  sidebarWrapper: {
    '& .MuiDrawer-root': {
      '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.normalDarker,
        padding: '0rem 1rem'
      }
    }
  }
}));

export default useStyles;
