import { Box } from '@mui/system';
import useToggle from 'hooks/useToggle';
import CustomAppBar from './appbar';
import Drawer from 'layouts/dashboardLayout/sidebar';
import Styles from './styles';
import { Outlet } from 'react-router-dom';

const DasboardLayout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useToggle(true);
  const classes = Styles();

  console.log('drawer', drawerOpen);

  return (
    <Box>
      <Box className={classes.sidebarWrapper}>
        <CustomAppBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </Box>

      <Outlet />
      {/* <Box sx={drawerOpen ? customStyles.openState : customStyles.closedState}>{children}</Box> */}
    </Box>
  );
};

export default DasboardLayout;
