import * as actions from './types';
const initialState = {
  isLoading: false,
  agreementTypes: [],
  agreement_status_loading: false
};

export const agreementTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.AGREEMENT_TYPE_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case actions.AGREEMENT_TYPE_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agreementTypes: action.payload
      };

    case actions.AGREEMENT_TYPE_FETCH_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case actions.AGREEMENT_CHANGE_BEGIN:
      return { ...state, agreement_status_loading: true };

    case actions.AGREEMENT_CHANGE_ERROR:
    case actions.AGREEMENT_CHANGE_SUCCESS:
      return { ...state, agreement_status_loading: false };

    default:
      return state;
  }
};
