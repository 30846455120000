/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Box, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

/* Components */
import SearchBar from 'components/globals/searchbar/SearchBar';
import CompanyTable from 'components/Company/CompanyTable';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'components/UI/Breadcrumbs';

/* Style */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    '& .saveButton': {
      background: theme.palette.primary.normal,
      textTransform: 'capitalize',
      borderRadius: '2px',
      color: '#fff',
      fontSize: '13px',
      height: '28px'
    },
    '& .saveButton:hover': {
      background: theme.palette.primary.normal
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Company = () => {
  const classes = useStyles();

  return (
    <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className={classes.title}>
          <Typography sx={{ fontSize: '16px' }}>Company</Typography>
          <Breadcrumbs />
        </Box>
        <Box display="flex" alignItems="center" columnGap={2}>
          <SearchBar />
          <Link to="/app/company/add" style={{ textDecoration: 'none' }}>
            <Box className={classes.button}>
              <Button className="saveButton">
                <Add sx={{ marginRight: '6px' }} />
                Add Company
              </Button>
            </Box>
          </Link>
        </Box>
      </Box>
      <Box mt={1}>
        <CompanyTable />
      </Box>
    </Box>
  );
};

export default Company;
