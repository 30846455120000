import { axiosInstance } from 'apis/_axios';

// create parameter
export const createParameterApi = (data) => {
  return axiosInstance().post('/admin/parameter', data);
};

// get all parameters
export const getParameterApi = () => {
  return axiosInstance().get('/admin/parameters', {
    paginate: 0
  });
};

// getParameter List by name
export const getParameterByNameApi = (name) => {
  return axiosInstance().get(`/admin/parameter-by-name/${name}`);
};

// getParameter By Id
export const getParameterByIdApi = (id) => {
  return axiosInstance().get(`/api/parameter/${id}`);
};

// edit Parameter
export const editParameterApi = (id, data) => {
  return axiosInstance().post(`/api/parameter/${id}`, data);
};

// delete parameter
export const deleteParameterApi = (id) => {
  return axiosInstance().delete(`/admin/parameter/${id}/delete`);
};
