/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

/* Style */
import Styles from '../styles';

const CompanyDetail = ({ company }) => {
  const classes = Styles();

  return (
    <Card className={classes.boxWrapper}>
      <CardContent>
        <Box className="body">
          <Box className="content">
            <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
              <Typography className="header" sx={{ fontSize: '14px !important' }}>
                Company Registration
              </Typography>
            </Box>
            <Box pl={4} py={2}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <Typography className="title">Name</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.name}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography className="title">Short Name</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.short_name}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography className="title">Phone number</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.phone_number}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography className="title">Tax id</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.tax_id}</Typography>
                  </Grid>{' '}
                  <Grid item md={4}>
                    <Typography className="title">Registration number</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.regon}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography className="title">BDO</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.bdo}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography className="title">Status</Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography className="description">{company?.status?.title}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {/* {company?.address?.length > 1 && (
            <Box className="content">
              <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
                <Typography className="header" sx={{ fontSize: '14px !important' }}>
                  Business Address
                </Typography>
              </Box>
              {company?.address?.[0].map((data) => (
                <Box pl={4} py={2}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Typography className="title">Country</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{data?.country}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="title">Street</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.street}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="title">Zip</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.zip}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="title">Post</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.post}</Typography>
                      </Grid>{' '}
                      <Grid item md={4}>
                        <Typography className="title">City</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.city}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="title">Community</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.community}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="title">County</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.county}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="title">Volvodeship</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Typography className="description">{company?.volvodeship}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Box>
          )} */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CompanyDetail;
