/* Build in libraries */
import React, { useEffect, useState } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import CustomTable from 'components/UI/Table';

import { fetchDiseaseCodes } from 'redux/miscellaneous/actions';

export default function DiseaseCode() {
  const dispatch = useDispatch();
  const { diseaseCodes, isLoading } = useSelector((state) => state.miscellaneous);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 100 },
    { id: 'code', label: 'Code', minWidth: 100 },
    { id: 'description', label: 'Title', minWidth: 150 },
  ];

  useEffect(() => {
    dispatch(fetchDiseaseCodes());
  }, []);

  return (
    <>
      <CustomTable columns={tableHead} rows={diseaseCodes} loading={isLoading} />
    </>
  );
}
