import React from 'react';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../../styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from 'components/globals/table';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box } from '@mui/system';
import { Button, Skeleton, Tooltip, Typography } from '@mui/material';
import { deleteParameter } from '../../redux/actions';
import TaxBandEdit from './TaxBandEdit';

const columns = [
  { title: '#', type: 'Index', minWidth: 20 },
  {
    field: 'date_s',
    title: 'Start Date',
    minWidth: 170,
    align: 'right'
  },
  {
    field: 'date_e',
    title: 'End Date',
    minWidth: 170,
    align: 'right'
  },
  {
    field: (row) => <TaxBandData parameter={row} />,
    title: 'Value',
    minWidth: 170,
    align: 'right'
  },
  {
    field: (row) => <Actions parameter={row} />,
    title: 'Actions.',
    minWidth: 170,
    align: 'center'
  }
];

export default function TaxBandTable() {
  const { singleParameterList, listByNameLoading } = useSelector((state) => state.parameter);

  // useEffect(() => {
  //   singleParameterList &&
  //     setTableData(
  //       singleParameterList.map((item) => ({
  //         id: item?.id,
  //         key: item?.par_name,
  //         start_date: item?.date_s,
  //         end_date: item?.date_e,
  //         value: item?.par_value
  //       }))
  //     );
  // }, [singleParameterList]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {!listByNameLoading ? (
        <CustomTable tableData={singleParameterList} tableHeads={columns} />
      ) : (
        <Skeleton height={100} />
      )}
    </Paper>
  );
}

const Actions = ({ parameter }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const [deleteModalOpen, openFunctionDelete] = useToggle(false);
  const { change_status_loading } = useSelector((state) => state.parameter);

  const dispatch = useDispatch();
  const handleClick = () => {
    openFunction();
  };

  const handleDelete = (id) => {
    dispatch(deleteParameter({ id: id, name: parameter?.par_name }, openFunctionDelete));
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title="Edit">
          <EditIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteOutlineIcon className={classes.deleteBtn} onClick={openFunctionDelete} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="60rem"
        headerText="Edit Parameter">
        <TaxBandEdit handleClose={openFunction} parameter={parameter} />
      </CustomModal>
      <CustomModal
        open={deleteModalOpen}
        handleClose={openFunctionDelete}
        height="auto"
        width="40rem"
        headerText="Delete Parameter">
        <Box marginTop={6} marginBottom={4}>
          <Typography variant="h5" textAlign="center">
            Are you sure to delete the parameter?
          </Typography>
          <Box marginX={4} textAlign="center">
            <Typography variant="p">
              Deleting intermediate parameter values might affect payroll calculations on those
              dates
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-around" marginBottom={3}>
          {!change_status_loading ? (
            <Button variant={'contained'} onClick={() => handleDelete(parameter?.id)}>
              Yes
            </Button>
          ) : (
            <Button variant={'contained'} disabled>
              Deleting
            </Button>
          )}
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openFunctionDelete}>
            No
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

const TaxBandData = ({ parameter }) => {
  return (
    <>
      {parameter?.par_value?.map((tax) => (
        <>
          <div>
            {tax?.tax_from || tax?.max_tax + '+'} {!tax?.max_tax && ' - ' + tax?.tax_to} :{' '}
            {tax?.tax_rate || tax?.max_rate}%
          </div>
        </>
      ))}
    </>
  );
};
