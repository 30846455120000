import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import useStyles from './styles';
import CancelIcon from '@mui/icons-material/Cancel';

import PropTypes from 'prop-types';

const CustomModal = ({ headerText, open, handleClose, children, width, height }) => {
  const classes = useStyles();

  const customModalStyles = {
    width: width || '74.4rem',
    height: height || '34.1rem',
    display: 'flex',
    flexDirection: 'column',
    background: '#F9F9FB'
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{ ...customModalStyles }} className={classes.root}>
        <Box className={classes.modalHeader}>
          <Box className={classes.modalHeaderText}>{headerText}</Box>
          <CancelIcon onClick={handleClose} className={classes.cancelItem} />
        </Box>
        <Box className={classes.modalBody}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  headerText: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.element,
  width: PropTypes.string,
  height: PropTypes.string
};

CustomModal.defaultProps = {
  headerText: 'This is a default header'
};
