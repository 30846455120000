/* Build in libraries */

/* Third party libraries */
import { Box } from '@mui/material';

/* Components */
import EditAccountingForm from 'components/Company/CompanyForm/EditCompanyForm';
import Breadcrumbs from 'components/UI/Breadcrumbs';

function EditAccounting() {
  return (
    <>
      <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
        <Breadcrumbs />
        <EditAccountingForm />
      </Box>
    </>
  );
}

export default EditAccounting;
