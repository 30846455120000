/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';

/* Components */
import Details from 'components/Dashboard/Details';
import Settings from 'components/Dashboard/Settings';

const Dashboard = () => {
  return (
    <>
      <Box pt={11} pl={32} pr={4.5} sx={{ background: '#F5F5F5', height: '100vh' }}>
        <Box>
          <Typography mb={2} variant="h4" sx={{ fontWeight: 'bold' }}>
            Welcome !
          </Typography>
          <Box>
            <Details />
          </Box>
          <Box>
            <Settings />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
