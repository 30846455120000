import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    borderRadius: '0px !important',
    boxShadow: '-1px 1px 7px 0px rgb(208 200 200 / 20%) !important',
    padding: '0rem',
    '& .title': {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.primary.dark,
      padding: '14px 32px 12px'
    },
    '& .MuiCardContent-root': {
      padding: '0px !important'
    },
    '& .formBodyWrapper': {
      padding: '14px 35px 31px'
    },
    '& .buttonWrapper': {
      backgroundColor: theme.palette.lightGrey[1],
      padding: '13px 20px 13px',
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '2rem',
      '& .saveButton': {
        background: theme.palette.primary.normal,
        borderRadius: '2px',
        color: '#fff',
        fontSize: '13px',
        height: '30px'
      },
      '& .cancelButton': {
        border: '1px solid #9D9CAF',
        borderRadius: '2px',
        color: '#383751',
        height: '30px',
        fontSize: '13px'
      }
    }
  }
}));

export default useStyles;
