import { Box } from '@mui/system';
import useStyles from './styles';
import { ReactComponent as AuthLogo } from 'assets/images/auth_logo.svg';
import PropTypes, { element } from 'prop-types';
import { Home } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

/**
 *
 * This is a layout for login and signup.
 * This comes with logo and heading
 * please do make sure to wrap child
 *
 * */
const AuthLayout = ({ children, heading, isSignIn }) => {
  const classes = useStyles();

  return (
    <>
      {(heading === 'Forget Password' || heading === 'Reset Password') && (
        <Box sx={{ background: '#F3F3F6' }} pl={5} pt={4}>
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Box className={classes.icon}>
              <Home />
            </Box>
          </Link>
        </Box>
      )}

      <Box className={heading === 'Sign in' ? classes.boxWrapper : classes.root}>
        <Box
          className={
            heading === 'Forget Password' ? classes.forgetPasswordContainer : classes.authContainer
          }>
          <Box className={classes.authHead}>
            <Box className={classes.smallIcon} ml="325px" bgcolor="#E57171" />
            <Box className={classes.smallIcon} bgcolor="#3BAB7C" />
            <Box className={classes.smallIcon} bgcolor="#D2AF30" />
          </Box>
          <AuthLogo className={classes.authLogo} />
          <Box className={classes.authHeading}>{`${heading}`.toUpperCase()}</Box>
          <Box className={isSignIn ? classes.signInWrapper : classes.signUpWrapper}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};

// proptypes definition
AuthLayout.propTypes = {
  children: PropTypes.arrayOf(element),
  heading: PropTypes.string,
  isSignIn: PropTypes.bool
};

// default props
AuthLayout.defaultProps = {
  heading: 'Sign IN',
  isSignIn: true
};
export default AuthLayout;
