import { Grid } from '@mui/material';
import React from 'react';
import useStyles from './styles';

export default function ViewTaxOffice({ taxOffice }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <ul>
            <ViewList label="Voivodeship" value={taxOffice?.voivodeship} />
            <ViewList label="Type" value={taxOffice?.type} />
            <ViewList label="Name" value={taxOffice?.office} />
            <ViewList label="KOD" value={taxOffice?.code} />
          </ul>
        </Grid>
        <Grid item sm={6}>
          <ul>
            <ViewList label="City" value={taxOffice?.city} />
            <ViewList label="Street" value={taxOffice?.street} />
            <ViewList label="NR" value={taxOffice?.apartment_number} />
            <ViewList label="Post" value={taxOffice?.post_office} />
            <ViewList label="Unit Cost" value={taxOffice?.unit_code} />
          </ul>
        </Grid>
      </Grid>
    </>
  );
}

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
