import * as actions from './types';
const initialState = {
  parameters: [],
  adminParameters: [],
  loading: false,
  create_loading: false,
  single_parameter: null,
  singleParameterList: [],
  single_loading: false,
  listByNameLoading: false,
  edit_loading: false,
  change_status_loading: false
};

const newParameterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_PARAMETER_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_PARAMETER_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_PARAMETER_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_PARAMETER_BEGIN:
    case actions.GET_ADMINPARAMETER_BEGIN:
      return { ...state, loading: true };

    case actions.GET_PARAMETER_BYNAME_BEGIN:
      return { ...state, listByNameLoading: true, singleParameterList: [] };

    case actions.GET_PARAMETER_SUCCESS:
      return { ...state, parameters: action.payload, loading: false };

    case actions.GET_ADMINPARAMETER_SUCCESS:
      return { ...state, adminParameters: action.payload, loading: false };

    case actions.GET_PARAMETER_BYNAME_SUCCESS:
      return { ...state, singleParameterList: action.payload, listByNameLoading: false };

    case actions.GET_PARAMETER_ERROR:
    case actions.GET_ADMINPARAMETER_ERROR:
      return { ...state, loading: false };

    case actions.GET_PARAMETER_BYNAME_ERROR:
      return { ...state, listByNameLoading: false };

    case actions.GET_SINGLE_PARAMETER_BEGIN:
      return { ...state, single_loading: true, single_parameter: [] };

    case actions.GET_SINGLE_PARAMETER_SUCCESS:
      return { ...state, single_parameter: action.payload, single_loading: false };

    case actions.GET_SINGLE_PARAMETER_ERROR:
      return { ...state, single_loading: false };

    case actions.EDIT_PARAMETER_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_PARAMETER_ERROR:
    case actions.EDIT_PARAMETER_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.CHANGE_PARAMETER_BEGIN:
      return { ...state, change_status_loading: true };

    case actions.CHANGE_PARAMETER_ERROR:
    case actions.CHANGE_PARAMETER_SUCCESS:
      return { ...state, change_status_loading: false };

    case actions.CLEAR_SINGLE_PARAMETER:
      return { ...state, single_parameter: null };

    case actions.CLEAR_PARAMETER_BYNAME:
      return { ...state, singleParameterList: [] };

    default:
      return state;
  }
};

export default newParameterReducer;
