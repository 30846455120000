/* Build in libraries */
import React, { useEffect } from 'react';

/* Third party libraries */
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { HookFormCheckbox } from 'components/UI/Form/HookFormCheckbox/Index';

function CompanyUserForm() {
  return (
    <>
      <Box>
        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <HookFormInput name="name" label="Full Name" placeholder="Anmol Singh" />
            </Grid>
            <Grid item sm={6}>
              <HookFormInput name="email" label="Email Address" placeholder="singhs@gmail.com" />
            </Grid>
            <Grid item sm={6}>
              <HookFormCheckbox name="is_admin" type="checkbox" label="Is Admin" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CompanyUserForm;
