import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[700],
    width: '20rem'
  },
  value: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    letterSpacing: '0.8px'
  }
}));

export default useStyles;
