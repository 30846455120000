// login check
export const isLoggedIn = () => (localStorage.getItem('HRM_TOKEN') ? true : false);

export const parseAddress = (addressData = [], remove, removeKey) => {
  const finalAddress = addressData.map((address, index) => {
    if (index == 0) {
      return { ...address, same_address_type: '' };
    } else if (address.same_address_type) {
      return {
        type: address.type,
        same_address_type: address.same_address_type
      };
    } else {
      return address;
    }
  });
  return finalAddress;
};
