import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  console.log(theme);
  return {
    submitButton: {
      height: '3.7rem',
      fontFamily: 'Work Sans',
      fontSize: '5rem',
      width: '100%',
      background: '#496AD0 !important',
      color: '#fff !important'
    },
    endText: {
      fontWeight: '500 !important',
      fontSize: '1.5rem !important',
      fontFamily: 'Work Sans !important',
      color: theme.palette.grey[6]
    }
  };
});

export default useStyles;
