/* Build in libraries */
import { useEffect } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import CustomTable from 'components/UI/Table';

import { fetchDeregistrationCodes } from 'redux/miscellaneous/actions';

export default function Deregistration() {
  const dispatch = useDispatch();
  const { deregistration, deregistration_loading } = useSelector((state) => state.miscellaneous);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 150 }
  ];

  useEffect(() => {
    dispatch(fetchDeregistrationCodes());
  }, []);

  return (
    <>
      <CustomTable columns={tableHead} rows={deregistration} loading={deregistration_loading} />
    </>
  );
}
