/* eslint-disable */
import {
  ExitToAppOutlined,
  ExpandMore,
  HandshakeOutlined,
  HomeOutlined,
  KeyboardCommandKeyOutlined,
  LockOutlined,
  MiscellaneousServicesOutlined,
  SettingsOutlined,
  TuneOutlined
} from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Avatar, Drawer, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as LogoSidebar } from 'assets/images/logo_sidebar.svg';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'redux/auth/actions';
import useStyles from './styles';

const Sidebar = ({ drawerOpen, setDrawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const listItems = [
    {
      name: 'Dashboard',
      icon: <HomeOutlined className={classes.listItemIcons} />,
      route: '/app/dashboard'
    },
    {
      name: 'Company',
      icon: <KeyboardCommandKeyOutlined className={classes.listItemIcons} />,
      route: '/app/company'
    },
    {
      name: 'Accounting Office',
      icon: <AccountBalanceIcon className={classes.listItemIcons} />,
      route: '/app/accounting'
    },
    {
      name: 'Parameters',
      icon: <TuneOutlined className={classes.listItemIcons} />,
      route: '/app/parameters'
    },

    {
      name: 'Agreement',
      icon: <HandshakeOutlined className={classes.listItemIcons} />,
      route: '/app/agreements'
    },
    {
      name: 'Calendar',
      icon: <CalendarMonthIcon className={classes.listItemIcons} />,
      route: '/app/calendar'
    },
    {
      name: 'Miscellaneous',
      icon: <MiscellaneousServicesOutlined className={classes.listItemIcons} />,
      route: '/app/miscellaneous',
      disable: true,
      accordion: [
        {
          name: 'Tax office',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/miscellaneous/tax-office'
        },
        {
          name: 'NFZ Branch',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/miscellaneous/nfz-branch'
        },
        {
          name: 'Disease Codes',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/miscellaneous/disease-codes'
        },
        {
          name: 'Break Benefit Types',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/miscellaneous/break-benefit-types'
        },
        {
          name: 'Expiration code',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/miscellaneous/expiration-codes'
        },
        {
          name: 'Reason for deregistration',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/miscellaneous/deregistration'
        }
      ]
    },
    {
      name: 'Settings',
      icon: <SettingsOutlined className={classes.listItemIcons} />,
      disable: true,
      route: '/app/settings',
      accordion: [
        {
          name: 'Password',
          icon: <LockOutlined className={classes.listItemIcons} />,
          route: '/app/settings/password'
        }
      ]
    }
  ];

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Box style={{ background: 'red' }}>
      <Drawer
        sx={{
          width: '217px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '217px',
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}>
        {/* ---------------------- */}
        <Box className={classes.sidebarHeader}>
          <LogoSidebar className={classes.logo} />
        </Box>
        {/* -------------------------- */}
        <Box className={classes.listItems}>
          {listItems.map((item, index) => (
            <>
              {/* <Box
                className={`${classes.listItem} ${
                  pathname === item.route && classes.listItemActive
                }`}
                onClick={() => navigate(`${item?.route}`)}>
                {item.icon}
                <Typography className={classes.listItemText}>{item.name}</Typography>
              </Box> */}
              <SidebarItems item={item} index={index} />
            </>
          ))}
        </Box>
        <Box className={classes.boxWrapper}>
          <Avatar>B</Avatar>
          <Box>
            <Typography variant="h5">Name</Typography>
            <Typography>Admin</Typography>
          </Box>
        </Box>
        <Box className={classes.logoutButton} onClick={handleLogout}>
          <ExitToAppOutlined className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
          <Typography className={classes.listItemText}>Logout</Typography>
        </Box>
      </Drawer>
    </Box>
  );
};

const SidebarItems = ({ item, index }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);

  return (
    <>
      <Box
        key={index}
        className={`${classes.listItem} ${pathname === item.route && classes.listItemActive}`}
        onClick={() => (!item?.disable ? navigate(`${item?.route}`) : null)}>
        {item.icon}
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ width: '100%' }}
          onClick={() => setExpand(!expand)}>
          <Typography className={classes.listItemText}>{item.name}</Typography>
          {item?.accordion?.length > 0 && <ExpandMore ml={5} />}
        </Box>
      </Box>
      {item?.accordion?.length > 0 &&
        expand &&
        item?.accordion?.map((data) => (
          <Link to={data?.route} style={{ textDecoration: 'none' }}>
            <Box display="flex" className={classes.expandedItem}>
              {data?.icon}
              <Typography>{data?.name}</Typography>
            </Box>
          </Link>
        ))}
    </>
  );
};

export default Sidebar;
