import {
  fetchBreakBenefitTypesApi,
  fetchDeregistrationApi,
  fetchDiseaseCodesApi,
  fetchExpirationCodesApi,
  fetchNfzBranchApi,
  fetchTaxOfficeApi
} from 'apis/miscellaneous';
import * as actions from './types';

export const fetchTaxOffice = () => async (dispatch) => {
  dispatch({ type: actions.MISCELLANEOUS_LOADING });
  fetchTaxOfficeApi()
    .then((res) => {
      dispatch({
        type: actions.TAX_OFFICE_FETCH_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((err) => {
      dispatch({ type: actions.TAX_OFFICE_FETCH_ERROR });
    });
};

export const fetchNfzBranch = () => async (dispatch) => {
  dispatch({ type: actions.MISCELLANEOUS_LOADING });
  fetchNfzBranchApi()
    .then((res) => {
      dispatch({
        type: actions.NFZ_BRANCH_FETCH_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((err) => {
      dispatch({ type: actions.NFZ_BRANCH_FETCH_ERROR });
    });
};

export const fetchDiseaseCodes = () => async (dispatch) => {
  dispatch({ type: actions.MISCELLANEOUS_LOADING });
  fetchDiseaseCodesApi()
    .then((res) => {
      dispatch({
        type: actions.DISEASE_CODE_FETCH_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((err) => {
      dispatch({ type: actions.DISEASE_CODE_FETCH_ERROR });
    });
};

export const fetchBreakBenefitTypes = () => async (dispatch) => {
  dispatch({ type: actions.MISCELLANEOUS_LOADING });
  fetchBreakBenefitTypesApi()
    .then((res) => {
      dispatch({
        type: actions.BREAK_BENEFIT_TYPE_FETCH_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((err) => {
      dispatch({ type: actions.BREAK_BENEFIT_TYPE_FETCH_ERROR });
    });
};

export const fetchExpirationCodes = () => async (dispatch) => {
  dispatch({ type: actions.EXPIRATION_CODE_FETCH_BEGIN });
  fetchExpirationCodesApi()
    .then((res) => {
      dispatch({
        type: actions.EXPIRATION_CODE_FETCH_SUCCESS,
        payload: res.data?.data?.expiration_codes
      });
    })
    .catch((err) => {
      dispatch({ type: actions.EXPIRATION_CODE_FETCH_ERROR });
    });
};

export const fetchDeregistrationCodes = () => async (dispatch) => {
  dispatch({ type: actions.DEREGISTRATION_FETCH_BEGIN });
  fetchDeregistrationApi()
    .then((res) => {
      dispatch({
        type: actions.DEREGISTRATION_FETCH_SUCCESS,
        payload: res.data?.data?.deregistration_reasons
      });
    })
    .catch((err) => {
      dispatch({ type: actions.DEREGISTRATION_FETCH_ERROR });
    });
};
