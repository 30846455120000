/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Grid } from '@mui/material';
import { Business } from '@mui/icons-material';
import { ReactComponent as Company } from 'assets/images/company.svg';

/* Components */
import StaticCards from 'components/UI/StaticCards';

function Companies() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StaticCards icon={<Company />} title="Companies" count="60" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StaticCards icon={<Company />} title="Administration" count="50" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StaticCards icon={<Company />} title="Location" count="20" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StaticCards icon={<Company />} title="Product" count="50" />
        </Grid>
      </Grid>
    </>
  );
}

export default Companies;
