import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useStyles } from './styles';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { checkEqualDate } from 'utils/dateUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useDispatch } from 'react-redux';
import { deleteHoliday } from 'redux/calendar/actions';

function CalendarTable({ monthlyCalendar, monthlyClosing = [], currentDate }) {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const checkDate = (date) => {
    let holidayData = {
      title: '',
      id: null
    };
    if (monthlyClosing?.length > 0) {
      monthlyClosing.map((item) => {
        if (checkEqualDate(item?.closing_date, date)) {
          holidayData.title = item?.title;
          holidayData.id = item?.id;
        }
      });
    }
    return holidayData;
  };

  const handleDeleteAction = (id) => {
    openFunction();
    setDeleteId(id);
  };

  const handleDelete = () => {
    deleteId && dispatch(deleteHoliday(deleteId, openFunction, currentDate?.getFullYear()));
  };

  const checkHoliday = (index, date) => {
    const checkData = checkDate(date);
    const holiday = index > 4 || checkData?.id ? 'holiday' : '';
    return { day: holiday, title: checkData?.title, id: checkData?.id };
  };

  return (
    <Box marginTop={2}>
      <table className={classes.table} border>
        <thead>
          <th>Monday</th>
          <th>Tuesday</th>
          <th>Wednesday</th>
          <th>Thursday</th>
          <th>Friday</th>
          <th>Saturday</th>
          <th>Sunday</th>
        </thead>
        <tbody>
          {monthlyCalendar?.map((item, index) => (
            <tr key={index}>
              {item?.map((list, ind) => {
                const check = checkHoliday(ind, list?.date);

                return (
                  <td className={`${classes.date} ${check?.day}`} key={ind}>
                    {list?.day > 0 ? list?.day : ''}
                    {check?.id && (
                      <div className={classes.deleteEventWrap}>
                        <DeleteIcon onClick={() => handleDeleteAction(check?.id)} />
                      </div>
                    )}
                    <InfoCard title={check?.title} index={ind} />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText="Deleting Holiday">
        <div className={classes.cardWrapper}>
          <Box marginTop={3} display="flex" justifyContent="center" marginBottom={3}>
            <Typography variant="h5">Are you sure want to delete this holiday?</Typography>
          </Box>
          <Box className="buttonWrapper">
            <Button className="saveButton" onClick={handleDelete}>
              Confirm
            </Button>

            <Button className="cancelButton" onClick={openFunction}>
              Cancel
            </Button>
          </Box>
        </div>
      </CustomModal>
    </Box>
  );
}

export default CalendarTable;

const InfoCard = ({ title, index }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionSelectedData, setActionSelectedData] = useState(null);
  const open = Boolean(anchorEl);
  const handleActionButtonClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setActionSelectedData(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {title && (
        <div
          className={classes.eventWrap}
          onClick={(event) => handleActionButtonClick(event, index)}>
          <InfoIcon />
        </div>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '&  .MuiPaper-root': { border: '1px solid #f2ecec' },
          '& .MuiMenuItem-root': { fontSize: '11px' }
        }}
        elevation={0}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem>{title}</MenuItem>
      </Menu>
    </>
  );
};
