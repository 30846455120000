import { Box, Button, Grid, Typography } from '@mui/material';
import PasswordField from 'components/globals/passwordInput';
import React from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  saveButton: {
    background: `${theme.palette.primary.normal} !important`,
    textTransform: 'capitalize !important',
    borderRadius: '2px !important',
    color: '#fff !important',
    fontSize: '13px !important',
    height: '28px',
    '&:hover': {
      background: theme.palette.primary.normal
    }
  },
  title: {
    color: '#496AD0',
    fontSize: '11px',
    float: 'right',
    cursor: 'pointer'
  }
}));

const Password = () => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  return (
    <Box>
      <form>
        <Grid container>
          <Grid item md={4}>
            <PasswordField
              name={'old_password'}
              label="Old password"
              placeholder="Old Password"
              register={register}
              error={errors.password}
            />
            <Typography className={classes.title} mt={1}>
              Forget Password ?
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item md={4}>
            <PasswordField
              name={'new_password'}
              label="New password"
              placeholder="New Password"
              register={register}
              error={errors.password}
            />
          </Grid>
        </Grid>
        <br />

        <Grid container>
          <Grid item md={4}>
            <PasswordField
              name={'confirm_password'}
              label="Confirm password"
              placeholder="Confirm Password"
              register={register}
              error={errors.password}
            />
          </Grid>
        </Grid>
        <br />
        <Box mt={4}>
          <Button className={classes.saveButton}>Save</Button>
        </Box>
      </form>
    </Box>
  );
};

export default Password;
