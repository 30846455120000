import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignOffice, fetchAssignedCompanies, fetchWithoutOffice } from 'redux/accounting/actions';

const AssignCompany = ({ slug, handleClose, assign_company_loading }) => {
  const dispatch = useDispatch();
  const { without_office, assigned_companies, assigned_companies_loading } = useSelector(
    (state) => state.accounting
  );

  const [companyId, setCompanyId] = useState('');
  const handleChange = (e, data) => {
    setCompanyId(data?.value);
  };

  const handleSubmit = () => {
    const data = { company_slug: companyId, accounting_office_slug: slug };
    companyId && dispatch(assignOffice(data, slug, handleClear));
  };

  const handleClear = () => {
    setCompanyId('');
  };

  const offices = without_office?.map((list) => ({ label: list?.name, value: list?.slug })) || [];

  useEffect(() => {
    dispatch(fetchWithoutOffice());
    dispatch(fetchAssignedCompanies(slug));
  }, []);
  return (
    <>
      <Box padding={5} display="flex" justifyContent="space-between">
        {!assigned_companies_loading ? (
          <>
            <Autocomplete
              id="combo-box-demo"
              options={offices}
              onChange={handleChange}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select Company" />}
            />
            <Button variant="contained" onClick={handleSubmit}>
              Add
            </Button>
          </>
        ) : (
          <Box textAlign="center" display="flex" justifyContent="center" style={{ width: '100%' }}>
            <CircularProgress size={26} />
          </Box>
        )}
      </Box>
      <Box padding={2}>
        <Typography variant="h5" style={{ marginBottom: '8px' }}>
          Existing Companies
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Tax Id</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assigned_companies?.map((list, index) => (
              <TableRow key={list?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{list?.name}</TableCell>
                <TableCell>{list?.tax_id}</TableCell>
                <TableCell>{list?.status?.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default AssignCompany;
