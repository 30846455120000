import React from 'react';
import { Box } from '@mui/material';
import BreadCrumb from 'components/UI/breadCrumb';
import DiseaseCode from 'components/Miscellaneous/DiseaseCode';

function DiseaseCodePage() {
  return (
    <>
      <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
        <BreadCrumb itemName={'Miscellaneous'} page={'Disease Codes'} />
        <Box mt={1}>
          <DiseaseCode />
        </Box>
      </Box>
    </>
  );
}

export default DiseaseCodePage;
