import { HookForm } from 'components/UI/Form/HookForm';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import React from 'react';
import TaxBandForm from './TaxBandForm';
import * as Yup from 'yup';
import useStyles from '../../styles';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { upsertParameter } from 'components/NewParameters/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { changeDate } from 'utils/dateUtils';

function AddTaxBand({ handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { create_loading } = useSelector((state) => state.parameter);
  const defaultValues = {
    par_name: 'tax_band_rate',
    date_s: '',
    par_type: 'array',
    par_value: []
  };

  const validationSchema = Yup.object({
    date_s: Yup.date().required('Start Date is required'),
    par_value: Yup.array().required('Parameter is required')
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      upsertParameter({ ...data, date_s: changeDate(data.date_s, 'YYYY-MM-DD') }, handleClose)
    );
  };
  return (
    <div>
      <HookFormProvider
        defaultValues={defaultValues}
        resolver={useYupValidationResolver(validationSchema)}>
        <HookForm onSubmit={onSubmit}>
          <TaxBandForm handleClose={handleClose} />
          <Box className={classes.modalFooter}>
            <Box className={classes.modalFooterButtons}>
              {!create_loading ? (
                <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                  Save
                </Button>
              ) : (
                <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                  Submitting
                </Button>
              )}
              <Button
                className={classes.modalFooterCancel}
                variant={'outlined'}
                onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </HookForm>
      </HookFormProvider>
    </div>
  );
}

export default AddTaxBand;
