import { Card, CardContent } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ParameterTable from './ParameterTable';
import { getParameter } from './redux/actions';
import useStyles from './styles';
import Parameterpage from '../Parameters';

function Parameter() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSearch = () => {
    console.log('searched');
  };

  useEffect(() => {
    dispatch(getParameter());
  }, []);

  return (
    <>
      <Card className={classes.cardWrapper}>
        <CardContent>
          <ParameterTable />
        </CardContent>
      </Card>
    </>
  );
}

export default Parameter;
