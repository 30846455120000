import Protected from 'components/globals/protected';
import Home from 'components/locals';
import Accounting from 'pages/_private/Accounting';
import AddAccounting from 'pages/_private/Accounting/AddAccounting';
import EditAccounting from 'pages/_private/Accounting/EditAccounting';
import AgreementTable from 'pages/_private/Agreements/AgreementTable';
import Company from 'pages/_private/Company';
import AddCompany from 'pages/_private/Company/AddCompany';
import EditCompany from 'pages/_private/Company/EditCompany';
import Dashboard from 'pages/_private/Dashboard';
import BreakBenefitTypePage from 'pages/_private/Miscellaneous/BreakBenefitType';
import DeregistrationPage from 'pages/_private/Miscellaneous/Deregistration';
import DiseaseCodePage from 'pages/_private/Miscellaneous/DiseaseCode';
import ExpirationCodePage from 'pages/_private/Miscellaneous/ExpirationCode';
import NfzBranchPage from 'pages/_private/Miscellaneous/NfzBranch';
import TaxOfficePage from 'pages/_private/Miscellaneous/TaxOffice';
import Parameters from 'pages/_private/Parameters';
import Password from 'pages/_private/Settings/Password';
import CalendarPage from 'pages/_private/calendar';
import Login from 'pages/login';
import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboardLayout/index';

const IDK = () => 'this is idk';

const RouteList = () => {
  const routeList = [
    {
      path: '/',
      component: <Login />
    },
    {
      path: '/home',
      component: <Home />
    },
    {
      path: '/idk',
      component: <IDK />
    }
  ];

  return (
    <Routes>
      <Route
        path="/app"
        element={
          <Protected>
            <DashboardLayout />
          </Protected>
        }>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="parameters" element={<Parameters />} />
        <Route path="agreements" element={<AgreementTable />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="company">
          <Route path="add" element={<AddCompany />} />
          <Route index element={<Company />} />
          <Route path="edit/:id" element={<EditCompany />} />
        </Route>
        <Route path="accounting">
          <Route path="add" element={<AddAccounting />} />
          <Route index element={<Accounting />} />
          <Route path="edit/:id" element={<EditAccounting />} />
        </Route>
        <Route path="settings">
          <Route path="password" element={<Password />} />
        </Route>
        <Route path="miscellaneous">
          <Route path="tax-office" element={<TaxOfficePage />} />
          <Route path="nfz-branch" element={<NfzBranchPage />} />
          <Route path="disease-codes" element={<DiseaseCodePage />} />
          <Route path="break-benefit-types" element={<BreakBenefitTypePage />} />
          <Route path="expiration-codes" element={<ExpirationCodePage />} />
          <Route path="deregistration" element={<DeregistrationPage />} />
        </Route>
      </Route>
      {routeList.map(({ path, component }) => (
        <Route key={path} path={path} element={component} />
      ))}
    </Routes>
  );
};

export default RouteList;
