/* Build in libraries */
import { useEffect } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import CustomTable from 'components/UI/Table';

import { fetchExpirationCodes } from 'redux/miscellaneous/actions';

export default function ExpirationCode() {
  const dispatch = useDispatch();
  const { expirationCodes, expiration_loading } = useSelector((state) => state.miscellaneous);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 150 }
  ];

  useEffect(() => {
    dispatch(fetchExpirationCodes());
  }, []);

  return (
    <>
      <CustomTable columns={tableHead} rows={expirationCodes} loading={expiration_loading} />
    </>
  );
}
