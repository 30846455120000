import { axiosInstance } from 'apis/_axios';

export const fetchTaxOfficeApi = (data) => {
  return axiosInstance().get('/admin/tax-office?paginate=0');
};

export const fetchNfzBranchApi = (data) => {
  return axiosInstance().get('/admin/nfz-branch?paginate=0');
};

export const fetchDiseaseCodesApi = (data) => {
  return axiosInstance().get('/admin/disease-codes');
};

export const fetchBreakBenefitTypesApi = (data) => {
  return axiosInstance().get('/admin/break-benefit-types');
};

export const fetchExpirationCodesApi = (data) => {
  return axiosInstance().get('/admin/agreement-termination-data/expiration-codes');
};

export const fetchDeregistrationApi = (data) => {
  return axiosInstance().get('/admin/agreement-termination-data/deregistration-reasons');
};
