import {
  addCompanyAdminApi,
  addCompanyApi,
  changeCompanyStatusApi,
  changeUserStatusApi,
  editCompanyApi,
  fetchCompanyApi,
  fetchSingleCompanyApi,
  getCompanyAdminApi,
  removeUserApi,
  updateCompanyAdminApi
} from 'apis/company';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// CORS ERROR
export const addCompany =
  (data, redirectTo, setSubmitStatus, setBackValidation) => async (dispatch) => {
    try {
      const response = await addCompanyApi(data);
      redirectTo();
      successToast('Company added successfully');
    } catch (error) {
      setSubmitStatus(false);
      setBackValidation(error.response?.data?.errors);
      errorToast(error);
    }
  };

export const fetchCompany = (data, redirectTo, setSubmitStatus) => async (dispatch) => {
  dispatch({ type: actions.FETCH_COMPANY_BEGINS });
  try {
    const response = await fetchCompanyApi(data);
    dispatch({ type: actions.FETCH_COMPANY_SUCCESS, payload: response?.data });
  } catch (error) {
    errorToast(error);
    dispatch({ type: actions.FETCH_COMPANY_ERROR });
  }
};

export const editCompany =
  (data, slug, redirectTo, setSubmitStatus, setBackValidation) => async (dispatch) => {
    try {
      const response = await editCompanyApi(slug, data);
      redirectTo();
      successToast('Company updated successfully');
    } catch (error) {
      setSubmitStatus(false);
      setBackValidation(error.response?.data?.errors);
      errorToast(error);
    }
  };

export const fetchSingleCompany = (data, redirectTo, setSubmitStatus) => async (dispatch) => {
  dispatch({ type: actions.FETCH_SINGLE_COMPANY_BEGINS });
  try {
    const response = await fetchSingleCompanyApi(data);
    dispatch({ type: actions.FETCH_SINGLE_COMPANY_SUCCESS, payload: response?.data?.data });
  } catch (error) {
    errorToast(error);
    dispatch({ type: actions.FETCH_SINGLE_COMPANY_ERROR });
  }
};

export const updateCompanyAdmin =
  (company_id, data, handleClose, setBackValidation) => (dispatch) => {
    dispatch({ type: actions.EDIT_ADMIN_BEGINS });
    updateCompanyAdminApi(company_id, data)
      .then((res) => {
        dispatch({ type: actions.EDIT_ADMIN_SUCCESS });
        successToast('Company Admin Data Updated Successfully');
        handleClose && handleClose();
      })
      .catch((error) => {
        errorToast(error);
        setBackValidation(error.response?.data?.errors);
        dispatch({ type: actions.EDIT_ADMIN_ERROR });
      });
  };

export const getCompanyAdmin = (slug) => (dispatch) => {
  dispatch({ type: actions.FETCH_COMPANY_ADMIN_BEGIN });
  getCompanyAdminApi(slug)
    .then((res) => {
      dispatch({ type: actions.FETCH_COMPANY_ADMIN_SUCCESS, payload: res.data.data.admins });
    })
    .catch((error) => {
      dispatch({ type: actions.FETCH_COMPANY_ADMIN_ERROR });
      errorToast(error);
    });
};

export const addCompanyAdmin = (data, slug) => (dispatch) => {
  dispatch({ type: actions.CREATE_COMPANY_ADMIN_BEGIN });
  addCompanyAdminApi(data)
    .then((res) => {
      dispatch({ type: actions.CREATE_COMPANY_ADMIN_SUCCESS });
      successToast('Company User Added Successfully');
      slug && dispatch(getCompanyAdmin(slug));
    })
    .catch((error) => {
      dispatch({ type: actions.CREATE_COMPANY_ADMIN_ERROR });
      errorToast(error);
    });
};

export const changeUserStatus = (data) => (dispatch) => {
  dispatch({ type: actions.CHANGE_ADMIN_STATUS_BEGIN });
  changeUserStatusApi(data)
    .then((res) => {
      dispatch({ type: actions.CHANGE_ADMIN_STATUS_SUCCESS });
      successToast('Status Changed Successfully');
      data?.company_slug && dispatch(getCompanyAdmin(data?.company_slug));
    })
    .catch((error) => {
      dispatch({ type: actions.CHANGE_ADMIN_STATUS_ERROR });
      errorToast(error);
    });
};

export const removeUser = (data) => (dispatch) => {
  dispatch({ type: actions.DELETE_ADMIN_USER_BEGIN });
  removeUserApi(data)
    .then((res) => {
      dispatch({ type: actions.DELETE_ADMIN_USER_SUCCESS });
      successToast('User Removed Successfully');
      data?.company_slug && dispatch(getCompanyAdmin(data?.company_slug));
    })
    .catch((error) => {
      dispatch({ type: actions.DELETE_ADMIN_USER_ERROR });
      errorToast(error);
    });
};

export const changeCompanyStatus = (slug, data, handleSuccess) => (dispatch) => {
  dispatch({ type: actions.CHANGE_COMPANY_STATUS_BEGIN });
  changeCompanyStatusApi(slug, data)
    .then((res) => {
      dispatch({ type: actions.CHANGE_COMPANY_STATUS_SUCCESS });
      handleSuccess && handleSuccess();
      dispatch(fetchCompany());
    })
    .catch((err) => {
      dispatch({ type: actions.CHANGE_COMPANY_STATUS_ERROR });
    });
};
