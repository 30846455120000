import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import useStyles from '../styles';
import ViewTable from './ViewTable';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import useToggle from 'hooks/useToggle';
import CustomModal from 'components/globals/modal';
import AddParameter from './AddParameter';
import { useDispatch } from 'react-redux';
import { getParameterByName } from '../redux/actions';

function ParameterView({ handleClose, parameter }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);

  useEffect(() => {
    parameter?.key && dispatch(getParameterByName(parameter.key));
  }, [parameter?.key]);

  return (
    <div className={classes.contentWrap}>
      <Box marginBottom={2} marginTop={3}>
        <Button variant="contained" onClick={openFunction}>
          <AddIcon /> Add New Value
        </Button>
      </Box>
      <ViewTable />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="25rem"
        width="30rem"
        headerText={`${parameter?.contribution}`}>
        <AddParameter handleClose={openFunction} parameter={parameter} />
      </CustomModal>
    </div>
  );
}

export default ParameterView;
