import * as actions from './types';
const initialState = {
  isLoading: false,
  calendarData: []
};

export const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CALENDAR_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case actions.FETCH_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        calendarData: action.payload
      };

    case actions.STORE_HOLIDAY_SUCCESS:
    case actions.DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        calendarData: []
      };

    case actions.FETCH_CALENDAR_ERROR:
    case actions.STORE_HOLIDAY_ERROR:
    case actions.DELETE_HOLIDAY_ERROR:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};
