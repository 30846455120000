import {
  addAccountingAdminApi,
  addAccountingApi,
  assignOfficeApi,
  fetchAccountingApi,
  fetchAssignedCompaniesApi,
  fetchWithoutOfficeApi
} from 'apis/accounting';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// CORS ERROR
export const addAccounting = (data, refetch) => (dispatch) => {
  dispatch({ type: actions.CREATE_ACCOUNTING_BEGIN });
  addAccountingApi(data)
    .then((res) => {
      dispatch({ type: actions.CREATE_ACCOUNTING_SUCCESS });
      successToast('Company added successfully');
      refetch && refetch();
    })
    .catch((err) => {
      dispatch({ type: actions.CREATE_ACCOUNTING_ERROR });
      errorToast(err);
    });
};

export const fetchAccounting = (data) => (dispatch) => {
  dispatch({ type: actions.FETCH_ACCOUNTING_BEGINS });
  fetchAccountingApi(data)
    .then((res) => {
      dispatch({ type: actions.FETCH_ACCOUNTING_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({ type: actions.FETCH_ACCOUNTING_ERROR });
      errorToast(err);
    });
};

export const addAccountingAdmin = (slug, data, handleClose) => (dispatch) => {
  dispatch({ type: actions.CREATE_ACCOUNT_ADMIN_BEGIN });
  addAccountingAdminApi(slug, data)
    .then((res) => {
      dispatch({ type: actions.CREATE_ACCOUNT_ADMIN_SUCCESS });
      handleClose && handleClose();
      successToast('Account User edited Successfully');
      dispatch(fetchAccounting({ page: 0, rowsPerPage: 10 }));
    })
    .catch((error) => {
      dispatch({ type: actions.CREATE_ACCOUNT_ADMIN_ERROR });
      errorToast(error);
    });
};

export const fetchWithoutOffice = (handleClose) => (dispatch) => {
  dispatch({ type: actions.FETCH_WITHOUT_OFFICE_BEGINS });
  fetchWithoutOfficeApi()
    .then((res) => {
      dispatch({ type: actions.FETCH_WITHOUT_OFFICE_SUCCESS, payload: res.data.data });
      handleClose && handleClose();
    })
    .catch((error) => {
      dispatch({ type: actions.FETCH_WITHOUT_OFFICE_ERROR });
      errorToast(error);
    });
};

export const fetchAssignedCompanies = (slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.FETCH_ASSIGNED_COMPANIES_BEGINS });
  fetchAssignedCompaniesApi(slug)
    .then((res) => {
      dispatch({
        type: actions.FETCH_ASSIGNED_COMPANIES_SUCCESS,
        payload: res.data.data.companies
      });
      handleClose && handleClose();
    })
    .catch((error) => {
      dispatch({ type: actions.FETCH_ASSIGNED_COMPANIES_ERROR });
      errorToast(error);
    });
};
export const assignOffice = (data, slug, handleClose) => (dispatch) => {
  dispatch({ type: actions.ASSIGN_OFFICE_BEGINS });
  assignOfficeApi(data)
    .then((res) => {
      dispatch({ type: actions.ASSIGN_OFFICE_SUCCESS });
      dispatch(fetchAssignedCompanies(slug));
      successToast('Company Assigned  Successfully');

      handleClose && handleClose();
    })
    .catch((error) => {
      dispatch({ type: actions.ASSIGN_OFFICE_ERROR });
      errorToast(error);
    });
};
