import { Box, Skeleton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { changeDate, dateDetail } from 'utils/dateUtils';
import CalendarTable from './CalendarTable';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCalendar } from '../../redux/calendar/actions';
import CustomModal from 'components/globals/modal';
import AddCalendar from './AddCalendar';

function Calendar({ modalOpen, openFunction }) {
  const classes = useStyles();
  const { calendarData, isLoading } = useSelector((state) => state.calendar);
  const [value, setValue] = useState(new Date());
  const [monthlyCalendar, setMonthlyCalendar] = useState([]);
  const dispatch = useDispatch();
  const [monthlyClosing, setMonthlyClosing] = useState([]);
  const [workingDays, setWorkingDays] = useState();

  useEffect(() => {
    const monthDetail = dateDetail(value);
    if (monthDetail) {
      const sum = monthDetail?.difference + monthDetail?.weekDay + 1;
      const loop = Math.ceil(sum / 7);
      const totalDay = [];
      setMonthlyCalendar([]);
      [...Array(sum - 1).keys()].map(
        (index) =>
          totalDay.push({
            day: index + 1 >= monthDetail.weekDay ? index - monthDetail.weekDay + 2 : 0,
            date:
              index + 1 >= monthDetail.weekDay
                ? moment(
                    `${value?.getMonth() + 1}-${
                      index - monthDetail.weekDay + 2
                    }-${value?.getFullYear()}`
                  )
                : ''
          })
        // setTotalDay((prev) => [...prev, { day: index - monthDetail.weekDay }])
      );
      [...Array(loop).keys()].map((index) =>
        setMonthlyCalendar((prev) => [...prev, [...totalDay.slice(index * 7, (index + 1) * 7)]])
      );
      if (calendarData) {
        const thisMonthData = calendarData.find((item) => item?.month == monthDetail?.monthName);
        if (thisMonthData) {
          setMonthlyClosing(thisMonthData?.closings);
          setWorkingDays(thisMonthData?.working_days);
        } else {
          setMonthlyClosing([]);
          setWorkingDays(0);
        }
      }
    }
  }, [value, calendarData]);

  useEffect(() => {
    dispatch(fetchCalendar(value?.getFullYear()));
  }, [value?.getFullYear()]);

  return (
    <>
      <Box marginTop={2}>
        <div className={classes.topBar}>
          <div className={classes.cpTitle}>
            Total Working Days: {workingDays ? workingDays : <Skeleton width={60} />}
          </div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Basic example"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <div className={classes.month}>{changeDate(value, 'MMMM YYYY')}</div>
                  <div ref={inputRef}></div>
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </LocalizationProvider>
        </div>
        {!isLoading ? (
          <CalendarTable
            monthlyCalendar={monthlyCalendar}
            handleClose={openFunction}
            monthlyClosing={monthlyClosing}
            currentDate={value}
          />
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" minHeight="80vh">
            <CircularProgress />
          </Box>
        )}
      </Box>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText="Add Closing Period">
        <AddCalendar openFunction={openFunction} currentDate={value} />
      </CustomModal>
    </>
  );
}

export default Calendar;
