import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  short_name: Yup.string().required('Short name is required'),
  tax_id: Yup.string().required('Tax id is required'),
  regon: Yup.string().required('Registration no. is required'),
  krs: Yup.string().required('KRS is required'),
  phone_number: Yup.string().typeError('Must be a number').required('Phone number is required'),
  addresses: Yup.array(),
  user_name: Yup.string().required('User Name is required'),
  user_email: Yup.string().email().required('User Email is required'),
  company_detail_email: Yup.string().email().required('Company email is required,')
});
