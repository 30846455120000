/* Build in libraries */
import { useState } from 'react';

/* Third party libraries */
import { Box } from '@mui/system';
import { AppBar, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material';
import { NotificationsNoneRounded, Repeat, SearchRounded } from '@mui/icons-material';

/* Styles */
import useStyles from './styles';

/* Components */
import SearchBar from 'components/globals/searchbar/SearchBar';

const CustomAppBar = ({ setDrawerOpen, drawerOpen }) => {
  const classes = useStyles();
  const [search, setSearch] = useState(false);

  const customStyles = {
    drawerOpen: {
      width: `calc(100% - ${217}px)`,
      marginLeft: `${217}px`,
      transition: 'all .3s ease'
    },
    drawerClose: {
      width: '100%',
      marginLeft: `0px`,
      transition: 'all .3s ease'
    }
  };
  return (
    <AppBar
      sx={drawerOpen ? customStyles.drawerOpen : customStyles.drawerClose}
      className={classes.root}
      position="fixed"
      open={true}>
      <CssBaseline />
      <Toolbar>
        <IconButton
          sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          color="inherit"
          aria-label="open drawer"
          onClick={setDrawerOpen}
          edge="start">
          <Repeat className={classes.logo} />
        </IconButton>
        <Box className={classes.sideBox}>
          <Box></Box>
          <Box className={classes.avatarArea}>
            <NotificationsNoneRounded sx={{ fontSize: '2rem', color: '#6C6B80' }} />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
