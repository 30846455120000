import { makeStyles } from '@mui/styles';

const usestyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff !important',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25) !important',
    height: '6.1rem !important'
  },
  sideBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarArea: {
    marginLeft: 'auto !important',
    display: 'flex',
    alignItems: 'center',
    height: '3.5rem',
    //

    '&>*': {
      margin: '0rem 1rem'
    },
    '& .searchBox': {
      width: '3.5rem',
      height: '3.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.grey[2],
      borderRadius: '50%',
      '& svg': {
        fontSize: '2rem',
        color: theme.palette.grey[1]
      }
    }
  },
  avatar: {
    width: '2.6rem !important',
    height: '2.9rem !important',
    borderRadius: '2px !important'
  },
  imageText: {
    fontFamily: 'Euclid',
    fontWeight: '500 !important',
    lineHeight: '16.48px !important',
    fontSize: '13px !important'
  },
  logo: {
    cursor: 'pointer',
    color: theme.palette.grey[5],
    fontSize: '2rem'
  }
}));

export default usestyles;
