const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('HRM_REFRESH_TOKEN');
  return refreshToken;
};

const getLocalAccessToken = () => {
  const token = localStorage.getItem('HRM_TOKEN');
  return token;
};

const updateLocalAccessToken = (Token) => {
  localStorage.setItem('HRM_TOKEN', Token);
};
const updateLocalRefreshToken = (refreshToken) => {
  localStorage.setItem('HRM_REFRESH_TOKEN', refreshToken);
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken
};

export default TokenService;
