import moment from 'moment';

export const singleParameterParser = (data) => {
  let type = '';
  let val = data.par_value;
  if (isNaN(val)) {
    type = 'string';
  } else {
    val = Number(val);
    const totalDecimals = countDecimals(val);
    if (totalDecimals == 0) {
      type = 'integer';
    } else if (totalDecimals > 0 && totalDecimals <= 2) {
      type = 'float';
    } else {
      type = 'double';
    }
  }
  return {
    id: data?.id || null,
    par_name: data.par_name,
    date_s: data.date_s ? moment(data?.date_s).format('YYYY-MM-DD') : '',
    par_type: type,
    par_value: val
  };
};

const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};
