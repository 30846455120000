import { makeStyles } from '@mui/styles';

const usestyles = makeStyles((theme) => ({
  sidebarHeader: {
    // width: '100% !important',
    marginTop: '1.7rem',
    margin: '0 2.2rem 0 2.6rem',
    color: 'red',
    height: '2.9rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    cursor: 'pointer',
    color: theme.palette.common.white,
    fontSize: '2rem'
  },
  listItems: {
    marginTop: '4rem'
  },
  listItem: {
    height: '3.6rem',
    padding: '0.8rem 0 0 2.3rem',
    display: 'flex',
    margin: '6px 0 ',
    color: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.normalDarker
    }
  },
  listItemActive: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.normalDarker
  },

  listItemIcons: {
    fontSize: '2rem !important'
  },
  listItemText: {
    marginLeft: '1rem !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '130% !important',
    fontFamily: 'Euclid !important'
  },
  logoutButton: {
    display: 'flex',
    margin: '1rem 0rem 2rem',
    height: '3.6rem',
    alignItems: 'center',
    background: theme.palette.common.white,
    padding: '0.9rem 1.2rem',
    cursor: 'pointer'
  },
  logoutIcon: {
    fontSize: '2.5rem !important'
  },
  boxWrapper: {
    margin: 'auto 0rem .5rem',
    display: 'flex',
    alignItems: 'center',
    columnGap: '1.5rem',
    borderTop: '1px solid #bcbbc6',
    paddingTop: '12px',
    '& .MuiTypography-root': {
      color: theme.palette.common.white
    }
  },
  expandedItem: {
    color: '#fff',
    margin: '5px 0px 5px 33px',
    borderLeft: `1px solid ${theme.palette.grey[4]}`,
    columnGap: '.5rem',
    alignItems: 'center',
    padding: '3px 0px ',
    '& svg': {
      marginLeft: '15px',
      fontSize: '1.5rem !important'
    },
    '& .MuiTypography-root': {
      fontSize: '12px'
    },
    '&:hover': {
      background: theme.palette.grey[2],
      color: theme.palette.primary.normalDarker
    }
  }
}));

export default usestyles;
