import { fetchAgreementTypeApi, updateAgreementTypeStatusApi } from 'apis/agreementType';
import * as actions from './types';

export const fetchAgreementType = () => async (dispatch) => {
  dispatch({ type: actions.AGREEMENT_TYPE_LOADING });
  fetchAgreementTypeApi()
    .then((res) => {
      dispatch({
        type: actions.AGREEMENT_TYPE_FETCH_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((err) => {
      dispatch({ type: actions.AGREEMENT_TYPE_FETCH_ERROR });
    });
};

export const changeAgreementType = (slug, data, handleClose) => (dispatch) => {
  dispatch({ type: actions.AGREEMENT_CHANGE_BEGIN });
  updateAgreementTypeStatusApi(slug, data)
    .then((res) => {
      dispatch(fetchAgreementType());
      dispatch({ type: actions.AGREEMENT_CHANGE_SUCCESS });
      handleClose && handleClose();
    })
    .catch((err) => {
      dispatch({ type: actions.AGREEMENT_CHANGE_ERROR });
    });
};
