import * as actions from './types';
const initialState = {
  isLoading: false,
  accounting: [],
  add_accounting_loading: false,
  add_account_admin_loading: false,
  assign_company_loading: false,
  without_office: [],
  without_office_loading: false,
  assigned_companies: [],
  assigned_companies_loading: false,
  assign_office_loading: false
};

export const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ACCOUNTING_BEGINS:
      return {
        ...state,
        accounting: [],
        isLoading: true
      };
    case actions.FETCH_ACCOUNTING_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case actions.FETCH_ACCOUNTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accounting: action.payload
      };

    case actions.FETCH_WITHOUT_OFFICE_BEGINS:
      return {
        ...state,
        without_office: [],
        without_office_loading: true
      };
    case actions.FETCH_WITHOUT_OFFICE_ERROR:
      return {
        ...state,
        without_office_loading: false
      };

    case actions.FETCH_WITHOUT_OFFICE_SUCCESS:
      return {
        ...state,
        without_office_loading: false,
        without_office: action.payload
      };

    case actions.FETCH_ASSIGNED_COMPANIES_BEGINS:
      return {
        ...state,
        assigned_companies: [],
        assigned_companies_loading: true
      };
    case actions.FETCH_ASSIGNED_COMPANIES_ERROR:
      return {
        ...state,
        assigned_companies_loading: false
      };

    case actions.FETCH_ASSIGNED_COMPANIES_SUCCESS:
      return {
        ...state,
        assigned_companies_loading: false,
        assigned_companies: action.payload
      };

    case actions.CREATE_ACCOUNTING_BEGIN:
      return { ...state, add_accounting_loading: true };

    case actions.CREATE_ACCOUNTING_ERROR:
    case actions.CREATE_ACCOUNTING_SUCCESS:
      return { ...state, add_accounting_loading: false };

    case actions.CREATE_ACCOUNT_ADMIN_BEGIN:
      return { ...state, add_account_admin_loading: true };

    case actions.CREATE_ACCOUNT_ADMIN_ERROR:
    case actions.CREATE_ACCOUNT_ADMIN_SUCCESS:
      return { ...state, add_account_admin_loading: false };

    case actions.ASSIGN_OFFICE_BEGINS:
      return { ...state, assign_office_loading: true };

    case actions.ASSIGN_OFFICE_ERROR:
    case actions.ASSIGN_OFFICE_SUCCESS:
      return { ...state, assign_office_loading: false };

    default:
      return state;
  }
};
