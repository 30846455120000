import { axiosInstance } from 'apis/_axios';

export const addAccountingApi = (data) => {
  return axiosInstance().post('/admin/account-office', data);
};

export const fetchAccountingApi = (data) => {
  const page = data?.page + 1 ?? 1;
  const rowsPerPage = data?.rowsPerPage ?? 10;
  return axiosInstance().get(`/admin/account-office?page=${page}&perPage=${rowsPerPage}`);
};

export const addAccountingAdminApi = (slug, data) => {
  return axiosInstance().put(`/admin/accounting-office/${slug}/admin`, data);
};

export const fetchWithoutOfficeApi = () => {
  return axiosInstance().get(`/admin/account-office/company-without-accountoffice`);
};

export const fetchAssignedCompaniesApi = (slug) => {
  return axiosInstance().get(`/admin/account-office/${slug}/existing-companies`);
};

export const assignOfficeApi = (data) => {
  return axiosInstance().patch(`/admin/account-office/company-assign`, data);
};
