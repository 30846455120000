import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { changeCompanyStatus } from 'redux/company/actions';

const StatusChange = ({ handleClose, slug, statusId }) => {
  const dispatch = useDispatch();
  const { companyStatusLoading } = useSelector((state) => state.company);

  const handleChange = () => {
    dispatch(changeCompanyStatus(slug, { value: statusId == 1 ? 0 : 1 }, handleClose));
  };
  return (
    <Box padding={4}>
      <Typography variant="h5" textAlign="center">
        Do you want to change Status?
      </Typography>
      <Box margin={4} display="flex" justifyContent="space-around">
        {!companyStatusLoading ? (
          <Button variant="contained" color="primary" onClick={handleChange}>
            Yes
          </Button>
        ) : (
          <Button variant="contained" color="primary" disabled>
            Submitting...
          </Button>
        )}

        <Button variant="contained" color="error" onClick={handleClose}>
          No
        </Button>
      </Box>
    </Box>
  );
};

export default StatusChange;
