/* Build in libraries */
import React, { useEffect, useState } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import CustomTable from 'components/UI/Table';

/* Services */
import { fetchCompany } from 'redux/company/actions';
import LoadingTable from 'components/UI/Loading/LoadingTable';
import CustomModal from 'components/globals/modal';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Footer from 'components/globals/modal/footer';
import { fetchAgreementType } from 'redux/agreementType/actions';
import { fetchTaxOffice } from 'redux/miscellaneous/actions';
import { VisibilityOutlined } from '@mui/icons-material';
import ViewTaxOffice from './components/ViewTaxOffice';
import useToggle from 'hooks/useToggle';

export default function TaxOffice() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(true);
  const [modalType, setModalType] = useState(true);
  const [data, setData] = useState([]);
  const { taxOffices, isLoading } = useSelector((state) => state.miscellaneous);

  const handleModalOpen = (type) => {
    setModalType(type);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 50 },
    { id: 'office', label: 'Office', minWidth: 150 },
    { id: 'street', label: 'Street', minWidth: 150 },
    { id: 'phone_number', label: 'Phone', minWidth: 100 },
    {
      id: 'function',
      field: (row) => {
        return <Actions row={row} />;
      },
      label: 'Actions',
      minWidth: 50,
      align: 'center'
    }
  ];

  useEffect(() => {
    dispatch(fetchTaxOffice());
  }, []);

  return (
    <>
      <CustomTable columns={tableHead} rows={taxOffices} loading={isLoading} />
    </>
  );
}

const Actions = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const handleClick = () => {
    openFunction();
  };
  return (
    <>
      <Box>
        <Tooltip title="View">
          <VisibilityOutlined onClick={handleClick} />
        </Tooltip>
      </Box>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="70rem"
        headerText="View Tax Office Information">
        <ViewTaxOffice handleClose={openFunction} taxOffice={row} />
      </CustomModal>
    </>
  );
};
