/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { AddCircle } from '@mui/icons-material';

/* Components */
import StaticCards from 'components/UI/StaticCards';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    border: '1px dashed #848489',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '4rem',
      color: theme.palette.primary.normal
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.dark,
      fontSize: '15px'
    }
  }
}));

function Settings() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} sx={{ paddingTop: '3rem' }}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <StaticCards icon={<SettingsIcon />} title="Settings" description="Update your profile" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Link to="/app/company/add" style={{ textDecoration: 'none' }}>
            <Box className={classes.boxWrapper}>
              <AddCircle />
              <Typography>Company</Typography>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default Settings;
