/* Build in libraries */
import React, { useEffect } from 'react';

/* Third party libraries */
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { useFormContext } from 'react-hook-form';

function TaxBandForm({ parameter }) {
  const { setValue } = useFormContext();
  console.log(parameter, 'par');
  useEffect(() => {
    if (parameter) {
      console.log(parameter);
      setValue('date_s', parameter?.date_s);
      parameter?.par_value?.map((value, index) =>
        Object.entries(value)?.map((item) => setValue(`par_value[${index}][${item[0]}]`, item[1]))
      );
    }
  }, [parameter]);
  return (
    <Box padding={4}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <HookFormInput
            name="date_s"
            label="Start Date"
            type="date"
            disabled={parameter ? true : false}
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="par_value[0][tax_from]"
            label="Taxable amount from"
            placeholder="1 PLN"
            type="number"
          />
        </Grid>
        <Grid sm={4} item>
          <HookFormInput
            name="par_value[0][tax_to]"
            label="Tax amount to"
            placeholder="3310 PLN"
            type="number"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="par_value[0][tax_rate]"
            label="Tax Rate"
            placeholder="17%"
            type="number"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="par_value[1][max_tax]"
            label="Maximum tax band (+)"
            placeholder="127001 PLN ++"
            type="number"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="par_value[1][max_rate]"
            label="Max Tax Rate"
            placeholder="32%"
            type="number"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TaxBandForm;
