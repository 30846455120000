/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Box } from '@mui/material';

/* Components */
import Breadcrumbs from 'components/UI/Breadcrumbs';
import EditCompanyForm from 'components/Company/CompanyForm/EditCompanyForm';

function EditCompany() {
  return (
    <>
      <Box pt={10} pl={30} pr={3} pb={5} sx={{ background: '#f5f5f5' }}>
        <Breadcrumbs />
        <EditCompanyForm />
      </Box>
    </>
  );
}

export default EditCompany;
