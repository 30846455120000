/* Build in libraries */
import React, { useEffect } from 'react';

/* Third party libraries */
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';

/* Components */
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

function EditForm({ showTitle = true, backValidation, setBackValidation }) {
  const { setError, watch, register, setValue } = useFormContext();
  const { singleCompany } = useSelector((state) => state.company);
  useEffect(() => {
    setValue('email', singleCompany?.user?.email);
    setValue('name', singleCompany?.user?.name);
  }, [singleCompany]);

  useEffect(() => {
    if (backValidation) {
      Object.entries(backValidation)?.map((item, index) =>
        setError(item[0], { message: item[1], type: 'custom' })
      );
    }
  }, [backValidation]);

  useEffect(() => {
    setBackValidation((prev) => ({ ...prev, email: '' }));
  }, [watch('email')]);

  return (
    <>
      <Box>
        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <HookFormInput name="name" label="Full Name" placeholder="Anmol Singh" />
            </Grid>
            <Grid item sm={6}>
              <HookFormInput name="email" label="Email Address" placeholder="singh@gmail.com" />
            </Grid>

            {!showTitle && (
              <Grid item sm={8}>
                <Box display="flex" alignItems="center" columnGap={1}>
                  <input type="checkbox" {...register('is_business_address_same')} />
                  <Typography sx={{ fontSize: '13px' }}>Send email for verification.</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default EditForm;
