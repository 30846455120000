import { Box, Button } from '@mui/material';
import { singleParameterParser } from 'helpers';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upsertParameter } from '../redux/actions';
import useStyles from '../styles';

function EditParameter({ handleClose, parameter }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(parameter?.par_value);
  const { create_loading } = useSelector((state) => state.parameter);

  const handleSet = (e) => {
    setValue(e.target.value);
  };
  console.log(parameter);
  const handleSubmit = () => {
    const finalData = singleParameterParser({
      id: parameter?.id,
      par_name: parameter?.par_name,
      date_s: parameter.date_s,
      par_type: '',
      par_value: value
    });
    dispatch(upsertParameter(finalData, handleClose));
  };

  return (
    <>
      <div className={classes.editRoot}>
        <ul>
          <li>
            <div className={classes.label}>Start Date</div>
            <div className={classes.inputText}>{parameter?.date_s}</div>
          </li>
          <li>
            <div className={classes.label}>Value</div>
            <input type="number" onChange={handleSet} value={value} />
          </li>
        </ul>
      </div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          {!create_loading ? (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
              Update
            </Button>
          ) : (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
              Updating
            </Button>
          )}
          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default EditParameter;
