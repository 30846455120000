import { SET_AUTH_USER } from './types';
const defaultState = {
  user: {}
};
const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
export default authReducer;
