import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import { Provider } from 'react-redux';
import Store from './redux/store';
import ErrorBoundary from 'utils/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from 'App';
import theme from 'themes';

// Main Component That Gets Mounted To The DOM
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
        <CssBaseline />
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
reportWebVitals();
