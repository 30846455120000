/* Build in libraries */
import { useEffect, useState } from 'react';

/* Third party libraries */
import { PersonOutline } from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useDispatch, useSelector } from 'react-redux';

/* Components */

/* Services */
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import CustomModal from 'components/globals/modal';
import { useLocation } from 'react-router-dom';
import { fetchAccounting } from 'redux/accounting/actions';
import AssignCompany from '../Form/assignCompany';
import EditUserDetails from '../Form/EditUserDetails';
import useStyles from '../styles';

export default function AccountingTable() {
  const dispatch = useDispatch();
  const { accounting, isLoading } = useSelector((state) => state.accounting);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchAccounting({ page: page, rowsPerPage: rowsPerPage }));
  }, [page, rowsPerPage]);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 50 },
    { id: 'name', label: 'Company Name', minWidth: 170, cursor: 'pointer' },
    { id: 'email', label: 'Email', minWidth: 170 },
    {
      id: 'address',
      label: 'Address',
      minWidth: 170
    },
    {
      id: 'tax_id',
      label: 'Tax Id',
      minWidth: 170
    },
    {
      id: 'regon',
      label: 'Reg no.',
      minWidth: 170
    },
    {
      id: 'actions',
      label: 'Action',
      minWidth: 170
    }
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {tableHead.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading ? (
            accounting?.account_offices?.length > 0 ? (
              accounting?.account_offices
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {tableHead.map((column) => {
                        const value = row[column.id];

                        return column.id == 'actions' ? (
                          <td>
                            <Actions row={row} id={row?.slug} detail={row} />
                          </td>
                        ) : column.id == 'sn' ? (
                          <TableCell>{index + 1}</TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ cursor: column?.cursor }}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow colSpan={tableHead.length}>
                <TableCell align="center">
                  <Typography variant="h5">No Data to Show</Typography>
                </TableCell>
              </TableRow>
            )
          ) : (
            [...Array(5).keys()]?.map((index) => (
              <TableRow key={index}>
                {[...Array(tableHead?.length).keys()]?.map((list) => (
                  <TableCell align="center" key={list}>
                    <Skeleton height={24} />
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={accounting?.pagination?.total_pages || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}

const Actions = ({ id, detail }) => {
  const location = useLocation();
  const [openModal, setOpenModal] = useState(true);
  const [modalType, setModalType] = useState(true);
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleModalOpen = (event, type, slug) => {
    event.stopPropagation();
    // const companyDetail = companies?.data?.find((data) => data?.slug === slug);
    // setDetail(companyDetail);
    setModalType(type);
    setOpenModal(true);
  };
  return (
    <>
      <div className={classes.iconWrapper}>
        {/* <Link to={`${location?.pathname}/edit/${id}`}>
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </Link> */}
        <Tooltip title="Edit User">
          <PersonOutline onClick={(event) => handleModalOpen(event, 'userDetail', id)} />
        </Tooltip>
        <Tooltip title="Assign Company">
          <ApartmentIcon onClick={(event) => handleModalOpen(event, 'assignCompany', id)} />
        </Tooltip>
      </div>
      {modalType === 'userDetail' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={`Update ${detail?.name}'s User`}>
          <EditUserDetails handleClose={handleClose} slug={detail?.slug} data={detail.user} />
        </CustomModal>
      )}
      {modalType === 'assignCompany' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={`Assign Company`}>
          <AssignCompany handleClose={handleClose} slug={detail?.slug} />
        </CustomModal>
      )}
    </>
  );
};
