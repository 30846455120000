/* Build in libraries */
import React from 'react';

/* Third party libraries */
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

/* Components */
import Parameters from 'components/NewParameters/Parameter';

const ParametersPage = () => {
  return (
    <>
      <Box pt={10} pl={30} pr={3} sx={{ background: '#F5F5F5' }}>
        <Parameters />
      </Box>
    </>
  );
};

export default ParametersPage;
