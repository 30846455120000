/* Build in libraries */
import React, { useEffect, useState } from 'react';

/* Third party libraries */
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import * as yup from 'yup';

/* Style */
import useStyles from '../../styles';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import { HookForm } from 'components/UI/Form/HookForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCompany, updateCompanyAdmin } from 'redux/company/actions';
import EditForm from './EditForm';

const EditUserDetails = ({ handleClose, slug }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [backValidation, setBackValidation] = useState();
  const { singleCompany, edit_admin_loading } = useSelector((state) => state.company);

  const defaultValues = {
    email: '',
    name: ''
  };
  const validationSchema = yup.object().shape({
    email: yup.string().email().required('User Email  is required'),
    name: yup.string().required('User name is required')
  });

  useEffect(() => {
    slug && dispatch(fetchSingleCompany(slug));
  }, [slug]);

  const submit = (data) => {
    dispatch(
      updateCompanyAdmin(
        singleCompany?.id,
        {
          ...data,
          _method: 'put'
        },
        handleClose,
        setBackValidation
      )
    );
  };

  return (
    <HookFormProvider
      defaultValues={defaultValues}
      resolver={useYupValidationResolver(validationSchema)}>
      <HookForm onSubmit={submit}>
        <Box px={4.7} py={3}>
          <EditForm setBackValidation={setBackValidation} backValidation={backValidation} />
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!edit_admin_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                Save
              </Button>
            ) : (
              <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Saving
              </Button>
            )}

            <Button
              className={classes.modalFooterCancel}
              sx={{ background: '#fff', color: '#000' }}
              variant={'contained'}
              onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </HookForm>
    </HookFormProvider>
  );
};

export default EditUserDetails;
