export const CALENDAR_LOADING = 'CALENDAR_LOADING';

export const STORE_HOLIDAY_SUCCESS = 'STORE_HOLIDAY_SUCCESS';
export const STORE_HOLIDAY_ERROR = 'STORE_HOLIDAY_ERROR';

export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_ERROR = 'DELETE_HOLIDAY_ERROR';

export const FETCH_CALENDAR_SUCCESS = 'FETCH_CALENDAR_SUCCESS';
export const FETCH_CALENDAR_ERROR = 'FETCH_CALENDAR_ERROR';
